import React, { useState, useEffect, useCallback, Suspense, lazy } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Spinner, Table } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import SearchContainer from '../components/SearchContainer';
import { setPaginationParams, toggleReload } from '../redux/actions';
import Pagination from '../components/Pagination';

const Breadcrumbs = lazy(() => import('../components/Breadcrumbs'));

const api = process.env.REACT_APP_APIPATH;

const Records = () => {
  // dispatch is used to dispatch a redux action from a react component
  const dispatch = useDispatch();

  // load query parameters from redux store
  const _id = useSelector((state) => state.recordPagination._id);
  const label = useSelector((state) => state.recordPagination.label);
  const forename = useSelector((state) => state.recordPagination.forename);
  const surname = useSelector((state) => state.recordPagination.surname);
  const forenameSoundex = useSelector(
    (state) => state.recordPaginationforenameSoundex
  );
  const surnameSoundex = useSelector(
    (state) => state.recordPagination.surnameSoundex
  );
  const pseudonym = useSelector((state) => state.recordPagination.pseudonym);
  const gender = useSelector((state) => state.recordPagination.gender);
  const description = useSelector(
    (state) => state.recordPagination.description
  );
  const collection = useSelector((state) => state.recordPagination.collection);

  // load pagination parameters from redux store
  const limit = useSelector((state) => state.recordPagination.limit);
  const orderField = useSelector((state) => state.recordPagination.orderField);
  const orderDesc = useSelector((state) => state.recordPagination.orderDesc);
  const page = useSelector((state) => state.recordPagination.page);
  const totalItems = useSelector((state) => state.recordPagination.totalItems);
  const totalPages = useSelector((state) => state.recordPagination.totalPages);
  const reload = useSelector((state) => state.recordPagination.reload);

  // initiate component's state variables
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [prevLimit, setPrevLimit] = useState(null);
  const [prevOrderField, setPrevOrderField] = useState(null);
  const [prevOrderDesc, setPrevOrderDesc] = useState(null);
  const [prevPage, setPrevPage] = useState(null);

  // update redux store pagination and query params
  const updateStorePagination = useCallback(
    ({
      limitParam = null,
      orderFieldParam = null,
      orderDescParam = null,
      pageParam = null,
      totalItemsParam = null,
      totalPagesParam = null,
    }) => {
      const limitCopy = limitParam === null ? limit : limitParam;
      const orderFieldCopy =
        orderFieldParam === null ? orderField : orderFieldParam;
      const orderDescCopy =
        orderDescParam === null ? orderDesc : orderDescParam;
      const pageCopy = pageParam === null ? page : pageParam;
      const totalItemsCopy =
        totalItemsParam === null ? totalItems : totalItemsParam;
      const totalPagesCopy =
        totalPagesParam === null ? totalPages : totalPagesParam;
      const payload = {
        limit: limitCopy,
        orderField: orderFieldCopy,
        orderDesc: orderDescCopy,
        page: pageCopy,
        totalItems: totalItemsCopy,
        totalPages: totalPagesCopy,
      };
      dispatch(setPaginationParams('record', payload));
    },
    [dispatch, limit, orderDesc, orderField, page, totalItems, totalPages]
  );

  // this function loads the data. It is wrapped in a callback so that it won't be executed every time the component's state is updated
  const load = useCallback(async () => {
    const params = {
      limit,
      orderField,
      orderDesc,
      page,
    };
    if (_id !== null) {
      params._id = _id;
    }
    if (label !== '') {
      params.label = label;
    }
    if (forename !== '') {
      params.forename = forename;
    }
    if (surname !== '') {
      params.surname = surname;
    }
    if (forenameSoundex !== null) {
      params.forenameSoundex = forenameSoundex;
    }
    if (surnameSoundex !== null) {
      params.surnameSoundex = surnameSoundex;
    }
    if (pseudonym !== null) {
      params.pseudonym = pseudonym;
    }
    if (gender !== null) {
      params.gender = gender;
    }
    if (description !== null) {
      params.description = description;
    }
    if (collection !== '') {
      params.collection = collection;
    }
    // get data from api
    const response = await axios({
      method: 'get',
      url: `${api}people/`,
      crossDomain: true,
      params,
    })
      .then((res) => res)
      .catch((err) => console.log('err', err));
    // pass response data to the component
    if (response.status === 200) {
      setLoading(false);
      setItems(response.data.data.items);
      updateStorePagination({
        pageParam: response.data.data.page,
        totalItemsParam: response.data.data.totalItems,
        totalPagesParam: response.data.data.totalPages,
        orderDescParam: response.data.data.orderDesc,
      });
    }
  }, [
    _id,
    label,
    forename,
    surname,
    forenameSoundex,
    surnameSoundex,
    pseudonym,
    gender,
    description,
    collection,
    orderField,
    orderDesc,
    page,
    limit,
    updateStorePagination,
  ]);

  useEffect(() => {
    if (prevLimit !== limit) {
      setPrevLimit(limit);
    }
    if (prevOrderField !== orderField) {
      setPrevOrderField(orderField);
    }
    if (prevOrderDesc !== orderDesc) {
      setPrevOrderDesc(orderDesc);
    }
    if (prevPage !== page) {
      setPrevPage(page);
    }
    if (
      loading ||
      reload ||
      prevLimit !== limit ||
      prevOrderField !== orderField ||
      prevOrderDesc !== orderDesc ||
      prevPage !== page
    ) {
      load();
    }
  }, [
    loading,
    load,
    reload,
    orderField,
    orderDesc,
    page,
    limit,
    prevLimit,
    prevOrderField,
    prevOrderDesc,
    prevPage,
  ]);

  useEffect(() => {
    if (reload) {
      dispatch(toggleReload());
    }
  }, [dispatch, reload]);

  const updatePage = (value) => {
    if (value > 0 && value !== page) {
      updateStorePagination({ pageParam: value });
    }
  };

  const updateSort = (param = '') => {
    if (param !== '') {
      const direction = orderDesc !== 'asc' ? 'asc' : 'desc';
      updateStorePagination({
        orderFieldParam: param,
        orderDescParam: direction,
      });
      dispatch(toggleReload());
    }
  };

  const heading = 'Records';
  const breadcrumbsItems = [
    { label: heading, icon: 'pe-7s-id', active: true, path: '' },
  ];

  const output = loading ? (
    <tr>
      <td colSpan="6">
        <div className="loading-block">
          <Spinner color="info" />
          <i>loading... </i>
        </div>
      </td>
    </tr>
  ) : (
    items.map((item, i) => {
      const itemSurname = item?.personalDetails.surname;
      const itemforename = item?.personalDetails.forename;
      const itemCounty = item?.personalDetails.residence.map((e, j) => {
        const countyOutput = [];
        if (j > 0) {
          countyOutput.push(<span key={`comma-${e._id}`}>, </span>);
        }
        countyOutput.push(<span key={e._id}>{e.address.county}</span>);
        return countyOutput;
      });
      const itemDiocese = item?.personalDetails.residence.map((e, j) => {
        const dioceseOutput = [];
        if (j > 0) {
          dioceseOutput.push(<span key={`comma-${e._id}`}>, </span>);
        }
        dioceseOutput.push(<span key={e._id}>{e.address.diocese}</span>);
        return dioceseOutput;
      });
      const itemOccupation = item?.occupationalHistory.occupation;
      const itemEducation = item?.education.map((e, j) => {
        const educationOutput = [];
        if (j > 0) {
          educationOutput.push(<span key={`comma-${e._id}`}>, </span>);
        }
        educationOutput.push(<span key={e._id}>{e.course}</span>);
        return educationOutput;
      });
      const count = i + 1 + (page - 1) * limit;
      return (
        <tr key={item._id}>
          <td>{count}</td>
          <td key={item.surname}>
            <Link to={`/record/${item._id}`}>{itemSurname}</Link>
          </td>
          <td key={item.forename}>
            <Link to={`/record/${item._id}`}>{itemforename}</Link>
          </td>
          <td key={item.county}>{itemCounty}</td>
          <td key={item.diocese}>{itemDiocese}</td>
          <td key={item.occupation}>{itemOccupation}</td>
          <td key={item.education}>{itemEducation}</td>
        </tr>
      );
    })
  );

  let surnameIcon = [];
  if (orderField === 'personalDetails.surname') {
    if (orderDesc === 'asc') {
      surnameIcon = <i className="fa fa-caret-up" />;
    } else {
      surnameIcon = <i className="fa fa-caret-down" />;
    }
  }
  let forenameIcon = [];
  if (orderField === 'personalDetails.forename') {
    if (orderDesc === 'asc') {
      forenameIcon = <i className="fa fa-caret-up" />;
    } else {
      forenameIcon = <i className="fa fa-caret-down" />;
    }
  }

  return (
    <div className="container">
      <Suspense fallback={[]}>
        <Breadcrumbs items={breadcrumbsItems} />
      </Suspense>
      <h2>
        {heading} <small>[{totalItems}]</small>
      </h2>
      <SearchContainer />
      <Pagination
        currentPage={page}
        totalPages={totalPages}
        paginationFn={updatePage}
      />
      <div className="row">
        <div className="col-12">
          <Table responsive>
            <thead>
              <tr>
                <th style={{ width: 40 }}>#</th>
                <th
                  className="sortable"
                  onClick={() => updateSort('personalDetails.surname')}
                >
                  Surname {surnameIcon}
                </th>
                <th
                  className="sortable"
                  onClick={() => updateSort('personalDetails.forename')}
                >
                  Forename{forenameIcon}
                </th>
                <th>County</th>
                <th>Diocese</th>
                <th>Occupation</th>
                <th>Education</th>
              </tr>
            </thead>
            <tbody>{output}</tbody>
            <tfoot>
              <tr>
                <th>#</th>
                <th
                  className="sortable"
                  onClick={() => updateSort('personalDetails.surname')}
                >
                  Surname {surnameIcon}
                </th>
                <th
                  className="sortable"
                  onClick={() => updateSort('personalDetails.forename')}
                >
                  Forename{forenameIcon}
                </th>
                <th>County</th>
                <th>Diocese</th>
                <th>Occupation</th>
                <th>Education</th>
              </tr>
            </tfoot>
          </Table>
        </div>
      </div>
      <Pagination
        currentPage={page}
        totalPages={totalPages}
        paginationFn={updatePage}
      />
    </div>
  );
};
export default Records;
