import React, { useEffect, useState, Suspense, lazy } from 'react';
import axios from 'axios';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import '../assets/styles/record.scss';
import PeopleModal from '../components/PeopleModal';

const Breadcrumbs = lazy(() => import('../components/Breadcrumbs'));

const api = process.env.REACT_APP_APIPATH;

const Record = (props) => {
  const { match } = props;
  const { _id } = match.params;
  const [loading, setLoading] = useState(true);
  const [item, setItem] = useState(null);

  useEffect(() => {
    const load = async () => {
      const response = await axios({
        method: 'get',
        url: `${api}person/`,
        crossDomain: true,
        params: { _id },
      })
        .then((res) => res)
        .catch((err) => console.log('err', err));
      setLoading(false);
      setItem(response.data.data);
    };
    if (loading) {
      load();
    }
  }, [_id, loading]);

  let output = <Spinner color="info" />;
  let heading = '';
  if (!loading && item !== null) {
    heading = item?.label;
    output = <PeopleModal item={item} />;
  }

  const breadcrumbsItems = [
    { label: 'Records', icon: 'pe-7s-id', active: false, path: '/records' },
    { label: heading, icon: 'fa fa-file-text-o', active: true, path: '' },
  ];

  return (
    <div className="container">
      <Suspense fallback={[]}>
        <Breadcrumbs items={breadcrumbsItems} />
      </Suspense>
      <h2 className="record-label">{heading}</h2>
      <div className="row">
        <div className="col-12">{output}</div>
      </div>
    </div>
  );
};

Record.propTypes = {
  match: PropTypes.object.isRequired,
};
export default Record;
