import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import muahilogo from '../assets/images/logo-transparentx400.png';
import mUlogo from '../assets/images/M10520_Maynooth_University_Know_No_Bounds_Logo_English-RGB.jpg';
import tcdlogo from '../assets/images/tcd-logo-2x.png';
import kuleuvenlogo from '../assets/images/ku-leuven-logo.png';
import '../assets/styles/footer.scss';

const today = new Date();
const year = today.getFullYear();
const yearOutput = year === 2021 ? 2021 : `2021 - ${year}`;

const Footer = () => {
  const location = useLocation();
  const [height, setHeight] = useState('auto');
  const ref = useRef(null);
  const prevPathName = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const footer = ref.current;
      const box = footer.getBoundingClientRect();
      const bottom = box.y + box.height;
      if (window.innerHeight > bottom) {
        const diff = window.innerHeight - bottom;
        setHeight(box.height + diff);
      } else {
        setHeight('auto');
      }
    };
    if (location.pathname !== prevPathName.current) {
      prevPathName.current = location.pathname;
      handleResize();
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [location]);

  const style = { height };
  return (
    <div className="footer-container" ref={ref} style={style}>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <h4 className="footer-title">Affiliations</h4>
            <div className="affiliations">
              <div className="affiliation">
                <a
                  href="https://www.maynoothuniversity.ie/arts-and-humanities-institute"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={muahilogo}
                    alt="Arts & Humanities Institute - Maynooth University"
                    title="Arts & Humanities Institute - Maynooth University"
                    className="muahi-footer-logo img-fluid"
                  />
                </a>
              </div>
              <div className="affiliation">
                <a
                  href="https://www.maynoothuniversity.ie/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={mUlogo}
                    alt="Maynooth University"
                    title="Maynooth University"
                    className="mu-footer-logo img-fluid padding"
                  />
                </a>
              </div>
              <div className="affiliation">
                <a
                  href="https://www.tcd.ie/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={tcdlogo}
                    alt="Trinity College Dublin"
                    title="Trinity College Dublin"
                    className="mu-footer-logo img-fluid padding"
                  />
                </a>
              </div>
              <div className="affiliation">
                <a
                  href="https://www.kuleuven.be/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={kuleuvenlogo}
                    alt="KU LEUVEN"
                    title="KU LEUVEN"
                    className="mu-footer-logo img-fluid"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6" style={{ alignSelf: 'flex-end' }}>
            <div className="copyright-info">
              &copy; {yearOutput} Maynooth University / Arts and Humanities
              Institute. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
