import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import SearchForm from './SearchForm';
import { toggleReload } from '../redux/actions';

const SearchContainer = () => {
  const dispatch = useDispatch();

  const [showOptions, setShowOptions] = useState(false);

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (showOptions) {
      toggleOptions();
    }
    dispatch(toggleReload());
  };

  return (
    <div>
      <SearchForm handleSubmit={handleSubmit} />
    </div>
  );
};
export default SearchContainer;
