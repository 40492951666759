import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../assets/styles/recordModal.scss';

const api = process.env.REACT_APP_APIPATH;

const PeopleModal = (props) => {
  const { item } = props;
  const [content, setContent] = useState([]);

  function final(input) {
    const s = input.replace(/([A-Z])/g, ' $1').trim();
    const r = ':';
    let res = s.concat(r);
    if (s.match(/[0-9]/g)) {
      res = s.replace(/[0-9]/g, '-');
    }
    return res;
  }

  const outputDate = (newDate = null) => {
    if (newDate === null) {
      return '';
    }
    const date = new Date(newDate);
    let day = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();
    if (day < 10) {
      day = `0${day}`;
    }
    if (month < 10) {
      month = `0${month}`;
    }
    return `${year}-${month}-${day}`;
  };

  // this effect is executed every time the item changes
  useEffect(() => {
    // we define a recursive function to loop through the data
    const outputData = (data = null) => {
      if (data === null) {
        return [];
      }
      const output = [];
      // we are reading the data param object and extract it's keys
      const keys = Object.keys(data);
      // this is a list of elements we don't want to output to the screen
      const preventOutput = [
        '_id',
        'xmlid',
        '__v',
        'forenameSoundex',
        'surnameSoundex',
        'updatedAt',
        'createdAt',
      ];
      // this is the main loop. I always prefer for loops because they have the fastest performance
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        const value = data[key];
        let html = [];
        // we check if the key name is not in the list of elements we don't want to output to the screen
        if (preventOutput.indexOf(key) === -1) {
          // if the type of the value is a string and it's value is not blank then we add it to the output
          if (typeof value === 'string' && value.trim() !== '') {
            html = (
              <div key={key}>
                <span className="label first-cap">{final(key)} </span> {value}
              </div>
            );
            if (key.match(/start$/) || key.match(/end$/)) {
              html = (
                <div key={key}>
                  <span className="label first-cap">{final(key)} </span>{' '}
                  {outputDate(value)}
                </div>
              );
            }
          } else if (
            value !== null &&
            (value.length > 0 || Object.keys(value).length > 0)
          ) {
            // if the type of value is not a string and it has children then we run the function again recursively to get the output
            html = (
              <div key={key} className="has-children">
                <span className="label first-cap">{final(key)} </span>{' '}
                {outputData(value)}
              </div>
            );
          }
          output.push(
            <div key={key} className="record-row">
              {html}
            </div>
          );
        }
      }
      return output;
    };
    // we update the state content variable with the value returned from the output data function
    setContent(outputData(item));
  }, [item]);

  const xmlLink = `${api}download/xml/${item._id}`;
  const jsonLink = `${api}download/json/${item._id}`;
  return (
    <div>
      <div className="pull-right">
        <div>
          <b>Download files</b>
        </div>
        <a
          href={xmlLink}
          download
          className="btn btn-sm btn-info download-link"
        >
          XML
        </a>
        <a
          href={jsonLink}
          download
          className="btn btn-sm btn-info download-link"
        >
          JSON
        </a>
      </div>
      {content}
    </div>
  );
};

PeopleModal.propTypes = {
  item: PropTypes.object.isRequired,
};

export default PeopleModal;
