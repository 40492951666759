import React, { useState } from 'react';
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import logo from '../assets/images/logo192.png';
import '../assets/styles/nav.scss';

const TopNav = () => {
  const [open, setOpen] = useState(false);
  const toggleNavbar = () => {
    setOpen(!open);
  };

  return (
    <Navbar color="light" light expand="md">
      <NavbarBrand href="/" className="mr-auto logo">
        <img
          src={logo}
          title="The Irish in Europe Project"
          alt="The Irish in Europe Project"
          className="logo-img"
        />
        <span className="logo-text">
          The
          <br />
          Irish
          <br /> in Europe
          <br /> Project
        </span>
      </NavbarBrand>
      <NavbarToggler onClick={toggleNavbar} className="mr-2" />
      <Collapse isOpen={!open} navbar className="justify-content-end">
        <Nav navbar className="main-nav">
          <NavItem>
            <NavLink to="/" activeClassName="active">
              Home
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/records" activeClassName="active">
              Records
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/Guide" activeClassName="active">
              Guide to Databases
            </NavLink>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};
export default TopNav;
