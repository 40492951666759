const defaultState = {
  recordPagination: {
    _id: null,
    label: '',
    forename: '',
    surname: '',
    forenameSoundex: '',
    surnameSoundex: '',
    pseudonym: '',
    gender: '',
    description: '',
    collection: '',
    limit: 25,
    orderField: 'surname',
    orderDesc: 'desc',
    page: 1,
    reload: false,
    totalItems: 0,
    totalPages: 1,
  },
};
export default defaultState;
