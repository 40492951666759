import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Collapse,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { handleChange, clearParams } from '../redux/actions';
import '../assets/styles/search.scss';

const SearchForm = (props) => {
  // redux
  const dispatch = useDispatch();
  const collection = useSelector((state) => state.recordPagination.collection);
  const label = useSelector((state) => state.recordPagination.label);
  const surname = useSelector((state) => state.recordPagination.surname);
  const forename = useSelector((state) => state.recordPagination.forename);
  const forenameSoundex = useSelector(
    (state) => state.recordPagination.forenameSoundex
  );
  const surnameSoundex = useSelector(
    (state) => state.recordPagination.surnameSoundex
  );
  const pseudonym = useSelector((state) => state.recordPagination.pseudonym);
  const gender = useSelector((state) => state.recordPagination.gender);
  const description = useSelector(
    (state) => state.recordPagination.description
  );
  const { handleSubmit } = props;

  const [advancedOpen, setAdvancedOpen] = useState(false);

  const toggleAdvanced = () => {
    setAdvancedOpen(!advancedOpen);
  };

  const advancedOpenIcon = advancedOpen ? (
    <i className="fa fa-caret-up" />
  ) : (
    <i className="fa fa-caret-down" />
  );
  return (
    <Form className="searchForm" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-xs-12 col-sm-3">
          <Input
            type="select"
            name="collection"
            value={collection}
            onChange={(e) => dispatch(handleChange(e, 'recordPagination'))}
          >
            <option value="">All</option>
            <option value="france">France</option>
            <option value="spain">Spain</option>
            <option value="leuven">Leuven</option>
            <option value="brocklissferte">Brocklissferte</option>
            <option value="InnsStudents">InnsStudents</option>
          </Input>
        </div>
        <div className="col-xs-12 col-sm-9">
          <InputGroup className="search-input-group">
            <Input
              type="text"
              placeholder="Search..."
              name="label"
              onChange={(e) => dispatch(handleChange(e, 'recordPagination'))}
              value={label}
            />
            <InputGroupAddon addonType="append">
              <Button
                type="button"
                outline
                color="secondary"
                onClick={() => dispatch(clearParams())}
                className="clear-search"
              >
                <i className="fa fa-times" />
              </Button>
              <Button
                type="submit"
                outline
                color="secondary"
                className="submit-search"
              >
                <i className="fa fa-search" />
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="advanced-search-fields">
            <Button
              className="toggle-advanced-btn"
              type="button"
              onClick={() => toggleAdvanced()}
              outline
              size="sm"
            >
              Advanced search {advancedOpenIcon}
            </Button>
            <Collapse isOpen={advancedOpen}>
              <FormGroup>
                <Input
                  type="text"
                  placeholder="Surname..."
                  name="surname"
                  onChange={(e) =>
                    dispatch(handleChange(e, 'recordPagination'))
                  }
                  value={surname}
                />
              </FormGroup>
              <FormGroup>
                <Input
                  type="text"
                  placeholder="Forename..."
                  name="forename"
                  onChange={(e) =>
                    dispatch(handleChange(e, 'recordPagination'))
                  }
                  value={forename}
                />
                <Input
                  type="text"
                  placeholder="ForenameSoundex..."
                  name="forenameSoundex"
                  onChange={(e) =>
                    dispatch(handleChange(e, 'recordPagination'))
                  }
                  value={forenameSoundex}
                />
                <Input
                  type="text"
                  placeholder="SurnameSoundex..."
                  name="surnameSoundex"
                  onChange={(e) =>
                    dispatch(handleChange(e, 'recordPagination'))
                  }
                  value={surnameSoundex}
                />
                <Input
                  type="text"
                  placeholder="Pseudonym..."
                  name="pseudonym"
                  onChange={(e) =>
                    dispatch(handleChange(e, 'recordPagination'))
                  }
                  value={pseudonym}
                />
                <Input
                  type="text"
                  placeholder="Gender..."
                  name="gender"
                  onChange={(e) =>
                    dispatch(handleChange(e, 'recordPagination'))
                  }
                  value={gender}
                />
                <Input
                  type="text"
                  placeholder="Description..."
                  name="description"
                  onChange={(e) =>
                    dispatch(handleChange(e, 'recordPagination'))
                  }
                  value={description}
                />
                <div className="text-center search-btns">
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => dispatch(clearParams())}
                    size="sm"
                  >
                    Clear <i className="fa fa-times" />
                  </Button>
                  <Button type="submit" color="secondary" size="sm">
                    Submit <i className="fa fa-search" />
                  </Button>
                </div>
              </FormGroup>
            </Collapse>
          </div>
        </div>
      </div>
    </Form>
  );
};

SearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};
export default SearchForm;
