import React from 'react';
import TopNav from './TopNav';

const Header = () => (
  <div className="container-fluid header-container">
    <div className="container">
      <TopNav />
    </div>
  </div>
);
export default Header;
