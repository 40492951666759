import React from 'react';

const Home = () => (
  <div className="row">
    <div className="col-12">
      <h1>Welcome to the Irish in Europe Project</h1>
    </div>
    <section>
      <h2>Welcome: Fáilte</h2>
      <p>
        Welcome to the Irish in Europe Project [IEP], hosted jointly in{' '}
        <a
          href="https://www.maynoothuniversity.ie/arts-and-humanities-institute"
          target="_blank"
          rel="noopener noreferrer"
        >
          Maynooth University [MU] Arts and Humanities Institute
        </a>
        and{' '}
        <a
          href="https://www.maynoothuniversity.ie/history"
          target="_blank"
          rel="noopener noreferrer"
        >
          History Department
        </a>
        . Since its 1997 inception, IEP, co-directed by Professors{' '}
        <a
          href="https://www.maynoothuniversity.ie/people/marian-lyons"
          target="_blank"
          rel="noopener noreferrer"
        >
          Marion Lyons
        </a>{' '}
        and{' '}
        <a
          href="https://www.maynoothuniversity.ie/people/thomas-oconnor"
          target="_blank"
          rel="noopener noreferrer"
        >
          Thomas O’Connor
        </a>
        , has worked to foster research into early-modern Irish migration to the
        Continent. It organised a series of five international conferences,
        published five volumes of conference proceedings, contributed to the
        organisation of a major exhibition in the{' '}
        <a href="https://www.nli.ie/" target="_blank" rel="noopener noreferrer">
          National Library of Ireland
        </a>{' '}
        and built up a network of researchers and collaborators. IEP has a
        particular interest in making digital material on the European Irish
        available to the research community and the general public. Since IEP’s
        inception, its work has been supported by seed funding from the{' '}
        <a href="https://hea.ie/" target="_blank" rel="noopener noreferrer">
          HEA
        </a>
        (under PRTLI cycles I and III), the{' '}
        <a
          href="https://irelandfunds.org/chapters/worldwide/france/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ireland Fund de France
        </a>
        , the{' '}
        <a
          href="https://irishstudies.nd.edu/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Keough Institute
        </a>
        the{' '}
        <a
          href="https://irishheritageschool.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Irish Heritage Council
        </a>
        ,{' '}
        <a
          href="https://artsineducation.ie/en/organisations/kildare-education-centre/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Kildare Education Centre
        </a>
        , together with MU’s{' '}
        <a
          href="https://www.maynoothuniversity.ie/deans-office"
          target="_blank"
          rel="noopener noreferrer"
        >
          Deans’ Office and Publications Subcommittee
        </a>
        .
      </p>
    </section>
    <section>
      <h2>
        Introduction: The Irish in Europe Virtual Research Environment (VRE)
      </h2>
      <p>
        In 2007, IEP won a major research award from the{' '}
        <a
          href="https://research.ie/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Irish Research Council
        </a>
        . The award was intended to fund the development of a publicly
        accessible Virtual Research Environment [VRE] to house four legacy
        databases containing biographical information on early modern Irish
        migrants to Europe. The four legacy databases were:
      </p>
      <ol>
        <li>
          <strong>Irish Military in early modern France: </strong> this database
          contained 16,000 individual biographical records. It was compiled by{' '}
          <a
            href="https://www.tcd.ie/CISS/mmfrance2.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dr Colm Ó Conaill
          </a>{' '}
          in Trinity College Dublin from records concerning Irish soldiers in
          French service held in the{' '}
          <a
            href="https://www.servicehistorique.sga.defense.gouv.fr/en"
            target="_blank"
            rel="noopener noreferrer"
          >
            Archives de la Guerre, Vincennes
          </a>
          .
        </li>
        <li>
          <strong>Irish military in early modern Spain: </strong> this database
          was prepared by{' '}
          <a
            href="https://www.ucm.es/udmoderna/recio-morales,-oscar-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dr Oscar Recio Morales
          </a>{' '}
          (Complutensian University) for TCD’s{' '}
          <a
            href="https://www.tcd.ie/trinitylongroomhub/research/themes/center-for-new-irish-studies.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            Centre for Irish Scottish Studies
          </a>
          under the joint direction of{' '}
          <a
            href="https://www.tcd.ie/history/staff/cbrady.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            Ciaran Brady
          </a>
          ,{' '}
          <a
            href="https://www.tcd.ie/history/staff/ddickson.php"
            target="_blank"
            rel="noopener noreferrer"
          >
            David Dickson
          </a>{' '}
          and{' '}
          <a
            href="https://people.ucd.ie/declan.downey"
            target="_blank"
            rel="noopener noreferrer"
          >
            Declan Downey
          </a>
          . This resource contained about 15,000 individual biographical records
          on early modern Irish soldiers in Spanish service, drawn mainly from
          the{' '}
          <a
            href="https://www.culturaydeporte.gob.es/cultura/areas/archivos/mc/archivos/ags/portada.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Archivo General de Simancas
          </a>{' '}
          (Valladolid).
        </li>
        <li>
          <strong>Irish students in early modern France: </strong> compiled by{' '}
          <a
            href="https://www.history.ox.ac.uk/people/professor-laurence-brockliss#/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Prof Laurence Brockliss
          </a>{' '}
          (Oxford University) and{' '}
          <a
            href="https://blogs.univ-jfc.fr/universitas/chercheur-es/ferte-patrick/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Prof Patrick Ferté
          </a>{' '}
          (Université de Toulouse) from university records held in Paris,
          Toulouse and Cahors, this database contained the biographical records
          of about 2,000 Irish students.
        </li>
        <li>
          <strong>Irish students in early modern Belgium: </strong> Dr Joren
          Nilis (RIP) assembled this database from university records in the{' '}
          <a
            href="https://www.kuleuven.be/english/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Katholieke Universiteit Leuven/Louvain
          </a>
          . It concerned about 1200 Irish students who attended the university
          prior to the French Revolution.
        </li>
      </ol>
      <p>
        The IRC-funded project was led by Thomas O’Connor (MU History), Marian
        Lyons (MU History) and{' '}
        <a
          href="https://www.maynoothuniversity.ie/faculty-science-engineering/our-people/john-keating"
          target="_blank"
          rel="noopener noreferrer"
        >
          John Keating
        </a>{' '}
        (MU Computer Science). Damien Gallagher was the technician. The IEP team
        accessed the status of the legacy databases, cleansing and reformating
        the data prior to ingesting it into an{' '}
        <a
          href="https://www.w3schools.com/xml/xml_whatis.asp"
          target="_blank"
          rel="noopener noreferrer"
        >
          XML
        </a>{' '}
        database to facilitate basic querying and web hosting. The resulting VRE
        was launched in 2009, to coincide with a public exhibition on the early
        modern European Irish, hosted by the National Library of Ireland.
        <br />
        The funding crisis consequent on the economic crash of 2008-14 imposed a
        moratorium on further developments. Over time, technical issues,
        exacerbated by funding shortfalls, caused the VRE to deteriorate; in
        2015 it went off line. Although the data was secured, it took time to
        source funding to restore and technically update the VRE. Thanks to a
        number of{' '}
        <a
          href="https://www.maynoothuniversity.ie/news-events/mu-spur-2020-experience-more-summer"
          target="_blank"
          rel="noopener noreferrer"
        >
          MU Summer Programme of Undergraduate Research (SPUR)
        </a>{' '}
        grants, some preparatory restoration work was done on the data (2017-19)
        under the supervision of Dr John Keating. In 2021, grants from the MU
        History Department and the MU Arts and Humanities Institute permitted
        the reprocessing of the 35,000 + biographical records in the original
        VRE.During this process, a fifth legacy database had been added. This is
        a database of Irish students at{' '}
        <a
          href="https://www.chambersstudent.co.uk/the-bar/the-inns-of-court"
          target="_blank"
          rel="noopener noreferrer"
        >
          London’s Inns of Court
        </a>
        , compiled by{' '}
        <a
          href="https://www.tcd.ie/history/staff/brid-mcgrath.php"
          target="_blank"
          rel="noopener noreferrer"
        >
          Dr Bríd McGrath
        </a>
        .
      </p>
      <p>
        The project’s new technical infrastructure employs the MERN (
        <a
          href="https://www.mongodb.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Mongodb
        </a>
        ,{' '}
        <a
          href="https://expressjs.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Express
        </a>
        ,{' '}
        <a
          href="https://reactjs.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          React
        </a>
        ,{' '}
        <a
          href="https://nodejs.org/en/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Node
        </a>
        ) stack and is divided into two projects: i) a front-end user interface
        and ii) a web-
        <a
          href="https://www.w3schools.com/js/js_api_intro.asp"
          target="_blank"
          rel="noopener noreferrer"
        >
          API
        </a>
        The front-end UI is build with React, a{' '}
        <a
          href="https://www.javascript.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Javascript
        </a>{' '}
        framework for developing user interfaces. The web-API is build with
        Node.js, a Javascript runtime. The deployment of the project employs{' '}
        <a
          href="https://docs.docker.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          docker
        </a>
        , a container runtime for packaging and deploying the apps as
        containers.
      </p>
      <p>
        The technical updating was directed by{' '}
        <a
          href="https://www.maynoothuniversity.ie/people/stavros-angelis"
          target="_blank"
          rel="noopener noreferrer"
        >
          Stavros Angelis
        </a>
        , Senior Technical Office in the Arts and Humanities Institute, assisted
        by Meadhbh Healy, a postgraduate student in MU Computer Science. These
        data are once again on-line and available to researchers and the general
        public.
      </p>
    </section>
  </div>
);

export default Home;
