import Home from './views/Home';
import Records from './views/Records';
import Record from './views/Record';
import Error from './views/Error';
import Guide from './views/Guide';

const routes = [
  {
    path: '/',
    component: Home,
    name: 'Home',
    key: 0,
  },
  {
    path: '/records/',
    component: Records,
    name: 'records',
    key: 1,
  },
  {
    path: '/record/:_id/',
    component: Record,
    name: 'record',
    key: 2,
  },
  {
    path: '/Guide/',
    component: Guide,
    name: 'Guide to Databases',
    key: 3,
  },
  {
    path: '/404',
    component: Error,
    name: 'Not Found',
    key: 99,
  },
];
export default routes;
