import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';

import 'axios-progress-bar/dist/nprogress.css';
import './assets/fonts/baskervville/baskervville.css';
import './assets/fonts/font-awesome/css/font-awesome.min.css';
import './assets/fonts/pe-icon-7/css/pe-icon-7-stroke.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/app.scss';

import mainRoutes from './routes';

const routes = mainRoutes.map((route) => {
  let newRoute = [];
  if (route.component !== null) {
    newRoute = (
      <Route path={route.path} key={route.key} component={route.component} />
    );
  }
  if (route.name === 'Home') {
    newRoute = (
      <Route
        exact
        path={route.path}
        key={route.key}
        component={route.component}
      />
    );
  }
  return newRoute;
});
function App() {
  return (
    <Router basename="/">
      <div className="app-body">
        <Header />
        <div className="container">
          <div className="content-container">
            <Switch>{routes}</Switch>
          </div>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
