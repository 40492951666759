import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import PropTypes from 'prop-types';

const MainPagination = (props) => {
  const { currentPage, totalPages, paginationFn, className } = props;

  const createPagination = () => {
    let prevPage = 0;
    let nextPage = 0;

    if (currentPage < totalPages) {
      nextPage = currentPage + 1;
    }
    if (currentPage > 1) {
      prevPage = currentPage - 1;
    }
    const paginationItems = [];
    const paginationFirstItem = (
      <li key="first">
        <PaginationLink className="href-btn" onClick={() => paginationFn(1)}>
          <i className="fa-step-backward fa" />
        </PaginationLink>
      </li>
    );
    const paginationPrevItem = (
      <li key="prev">
        <PaginationLink
          className="href-btn"
          onClick={() => paginationFn(prevPage)}
        >
          <i className="fa-backward fa" />
        </PaginationLink>
      </li>
    );
    paginationItems.push(paginationFirstItem);
    paginationItems.push(paginationPrevItem);

    for (let j = 0; j < totalPages; j += 1) {
      const pageNum = j + 1;
      let pageActive = '';

      if (currentPage === pageNum) {
        pageActive = 'active';
      }

      let paginationItem = (
        <PaginationItem key={pageNum} className={pageActive}>
          <PaginationLink
            className="href-btn"
            onClick={() => paginationFn(pageNum)}
          >
            {pageNum}
          </PaginationLink>
        </PaginationItem>
      );
      if (pageActive === 'active') {
        paginationItem = (
          <PaginationItem key={pageNum} className={pageActive}>
            <PaginationLink>{pageNum}</PaginationLink>
          </PaginationItem>
        );
      }

      // normalize first page
      if (currentPage < 6 && j < 9) {
        paginationItems.push(paginationItem);
      }
      // noprmalize last page
      else if (currentPage >= totalPages - 4 && j > totalPages - 10) {
        paginationItems.push(paginationItem);
      }
      // the rest
      else if (j > currentPage - 6 && j < currentPage + 4) {
        paginationItems.push(paginationItem);
      }
    }

    const paginationNextItem = (
      <PaginationItem key="next">
        <PaginationLink
          className="href-btn"
          onClick={() => paginationFn(nextPage)}
        >
          <i className="fa-forward fa" />
        </PaginationLink>
      </PaginationItem>
    );
    const paginationLastItem = (
      <PaginationItem key="last">
        <PaginationLink
          className="href-btn"
          onClick={() => paginationFn(totalPages)}
        >
          <i className="fa-step-forward fa" />
        </PaginationLink>
      </PaginationItem>
    );
    paginationItems.push(paginationNextItem);
    paginationItems.push(paginationLastItem);

    return paginationItems;
  };

  const paginationItems = createPagination();
  return (
    <div className={`pagination-container ${className}`}>
      <Pagination>{paginationItems}</Pagination>
    </div>
  );
};

MainPagination.defaultProps = {
  className: '',
};
MainPagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  paginationFn: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default MainPagination;
