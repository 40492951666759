import axios from 'axios';

const api = process.env.REACT_APP_APIPATH;

export const handleChange = (e, key) => (dispatch, getState) => {
  const state = getState();
  const { target } = e;
  const value = target.type === 'checkbox' ? target.checked : target.value;
  const { name } = target;
  state[key][name] = value;
  dispatch({
    type: 'GENERIC_UPDATE',
    payload: state,
  });
};

export function fetchRecord(collection, id) {
  return async (dispatch, getState) => {
    const state = getState();
    const response = await axios
      .get(`${api}people/${collection}/${id}`)
      .then((res) => res)
      .catch((err) => console.log('err', err));
    dispatch({
      type: 'GENERIC_UPDATE',
      payload: {
        item: response.data,
        open: !state.open,
      },
    });
  };
}

export function toggleOpen() {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: 'GENERIC_UPDATE',
      payload: {
        open: !state.open,
      },
    });
  };
}

export function setParam(name, param = '') {
  return (dispatch, getState) => {
    const state = getState();
    const params = { ...state.queryStringParams };
    if (param !== '') {
      params[name] = param;
    } else {
      delete params[name];
    }
    dispatch({
      type: 'GENERIC_UPDATE',
      payload: {
        queryStringParams: params,
      },
    });
  };
}

export function clearParams() {
  return (dispatch, getState) => {
    const state = getState();
    const { recordPagination } = state;
    recordPagination._id = null;
    recordPagination.label = '';
    recordPagination.forename = '';
    recordPagination.surname = '';
    recordPagination.forenameSoundex = '';
    recordPagination.surnameSoundex = '';
    recordPagination.pseudonym = '';
    recordPagination.gender = '';
    recordPagination.description = '';
    recordPagination.collection = '';
    recordPagination.reload = true;

    dispatch({
      type: 'GENERIC_UPDATE',
      payload: {
        recordPagination,
      },
    });
  };
}

export function setCollection(collection) {
  return (dispatch) => {
    dispatch({
      type: 'GENERIC_UPDATE',
      payload: {
        collection,
        options: collection,
      },
    });
  };
}

export function setPaginationParams(type, params) {
  return (dispatch, getState) => {
    let payload = null;
    const pagination = `${type}Pagination`;
    const newValues = { ...getState()[pagination] };
    Object.keys(params).forEach((key) => {
      newValues[key] = params[key];
    });
    payload = {
      [pagination]: newValues,
    };
    if (payload === null) {
      return false;
    }
    dispatch({
      type: 'GENERIC_UPDATE',
      payload,
    });
    return false;
  };
}

export function toggleReload() {
  return (dispatch, getState) => {
    const state = getState();
    const { recordPagination } = state;
    const { reload } = recordPagination;
    recordPagination.reload = !reload;
    const payload = {
      recordPagination,
    };
    dispatch({
      type: 'GENERIC_UPDATE',
      payload,
    });
  };
}
