import React, { useState } from 'react';
import { Table, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const Guide = () => {
  const [activeTab, setActiveTab] = useState('1');
  const history = useHistory();

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeTab === '1' ? 'active' : ''}
            onClick={() => {
              setActiveTab('1');
              history.push('/Guide/leuven');
            }}
          >
            Leuven
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === '2' ? 'active' : ''}
            onClick={() => {
              setActiveTab('2');
              history.push('/Guide/spain');
            }}
          >
            Spain
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === '3' ? 'active' : ''}
            onClick={() => {
              setActiveTab('3');
              history.push('/Guide/france');
            }}
          >
            France
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === '4' ? 'active' : ''}
            onClick={() => {
              setActiveTab('4');
              history.push('/Guide/brocklissferte');
            }}
          >
            Brocklissferte
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeTab === '5' ? 'active' : ''}
            onClick={() => {
              setActiveTab('5');
              history.push('/Guide/innsStudents');
            }}
          >
            InnsStudents
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <div className="row">
            <div className="col-12">
              <h2>Leuven Database: database of Irish students in Leuven</h2>
              <h3>Key to Leuven Database: </h3>
              This database, compiled by Dr Jeroen Nilis, contains data of over
              1000 Irish students registered in the University of Leuven/Louvain
              between the mid sixteenth and late eighteenth century. It was
              published in <i>Archivium Hibernicum</i> 60 (2006-7), 1-304 and in
              Leuven University was founded in 1425 with faculties of canon law,
              civil law, medicine, arts and, from 1432, theology. Irish students
              appear from 1548 onwards. Up to 1797, 1,171 Irish (secular)
              students stayed at Leuven, which counted four Irish Colleges
              within its town walls: three religious houses and one College for
              pastoral or secular students. All were incorporated in the
              University. The information presented here has been gathered from
              university matriculation books, other university archival material
              and published sources. The complete list of sources used may be
              found at the end of this introduction.
              <br />
              Four sources were used: firstly, the university’s matriculation
              registers. The register for the second half of the sixteenth
              century is lost and there exists a gap from 1568 to 1616 for
              almost all documents of the central administration of the
              university. However, recent research has revealed that the
              promotions to
              <i>Magister Artium</i>
              (MA), and the results of the general concursus or final exam,
              which concluded the obligatory propedeutic period at the arts
              Faculty, were noted down for the period 1572-1643 in the Acta
              Facultatis Artium. These records are kept at the Royal or State
              Archives in Leuven and using them one can reconstruct a major part
              of the lost Matriculation Registers, covering the period
              1568-1616. The contents of the eighth volume (1683-1734), also
              missing, can be reconstructed, using the accounts of the
              university
              <i>receptor</i>.
              <br />
              The second core source used was the record of the promotions in
              arts. These registers run from 24 May 1427 to Oct. 1447; 9 Nov.
              1482 to 20 June; 23 June 1508 to 27 Sept. 1511. There is a gap
              until 1572, as volumes six to eight (1511-1571) are lost but Acta
              survive for the whole period 1572 to 1791. From 1643 onwards the
              promotion lists were recorded in separate registers. After 1616
              the results of the promotions were published. The printed
              promotion lists did not always mention the <i>postlineales</i> or
              those <i>promoti post medium</i>. The Acta were consulted for the
              period 1572-1643, the
              <i>iLibri Promotionum</i> for the period 1643-1797.
              <br />
              The third source consulted was the{' '}
              <i>Libri Computuum Receptorum Universitatis</i>, the university
              account books. They list the revenues of particular accounts, for
              instance those of the University Hall and the Wine Cellar; the
              also include the dues paid for matriculation, academic acts,
              admission to the University Council, nominations and others. These
              account books are not complete for the whole period 1425-1797.
              They run from 21 Dec. 1521 to 24 June 1556. Then, as with the{' '}
              <i>Libri Intitulatorum</i>, there is a major gap from 1556 to 21
              Dec. 1621. Almost unbroken series survive to 1797, with minor
              gaps.
              <br />A fourth major source are the lists of the students of Holy
              Trinity College (1658-1797), the Leuven Latin school administered
              by the Arts Faculty. The <i>Minervalia</i> or{' '}
              <i>Manuale Minervalium</i> are preserved for the period Oct. 1747
              to Oct. 1790. Some other lists of this school, including that of
              books distributed to the laureates, were consulted (1723-91).
              Account books of the Vaulx College (1592-1657), the predecessor to
              the Holy Trinity College, were checked for Irish names.
              <br />
              These lists were supplemented with bursary lists of the Great
              College of the Holy Spirit and the Irish Pastoral college. E.H.J
              Reusens accumulated and published a considerable body of
              information in his
              <i>Documents relatifs à l’histoire de l’Université de Louvain</i>
              based on the manuscript
              <i>Historia Universitatis Lovaniensis</i>
              by J.L. Bax. The indexes of
              <i>Collectanea Hibernica</i> and <i>Archivium Hibernicum</i>,
              <i>Irish Ecclesiastical Record</i>,<i>Repertorium Novum</i> were
              also consulted. Other Irish and Flemish journals were browsed for
              Leuven Irish references, including
              <i>
                Journal of the Waterford and South-East of Ireland
                Archaeological Society
              </i>
              . The Archdukes Albrecht and Isabella were concerned with the
              English, Scottish and Irish refugees in the Southern Netherlands.
              They regularly received begging letters for financial aid, which
              were often granted. These requests for support are preserved in
              the State and Audience Papers, which were partially browsed for
              the years 1607-09 and 1614-17, and 1622-26. A more thorough search
              would certainly yield more information of Irish interest.
              <br />
              <br />
              Key to primary sources for Leuven/Louvain entries:
              <br />
              <br />
              <Table className="db-key" striped>
                <thead>
                  <tr>
                    <th>Abbreviation</th>
                    <th>Meaning</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Abp. </td>
                    <td>Archbishop </td>
                  </tr>
                  <tr>
                    <td>ACB</td>
                    <td>
                      {' '}
                      J. Nauwelaers, Histoire des avocats au souverain Conseil
                      de Brabant (2 vols, Brussels, 1947)
                    </td>
                  </tr>
                  <tr>
                    <td>ACT</td>
                    <td>
                      1704 W[illiam] J. W[alsh] (ed.), ‘An Act for Registering
                      the Popish Clergy 1704’ in I.E.R., xx (1876), pp 219-312,
                      338-360, 376-408, 420-456, 464-500, 512-550.
                    </td>
                  </tr>
                  <tr>
                    <td>APF</td>
                    <td>
                      Archivio della Sacra Congregazione di Propaganda Fide,
                      Rome
                    </td>
                  </tr>
                  <tr>
                    <td>APF, ACTA</td>
                    <td>
                      Examined by Hugh Fenning, O.P. His notes were used in the
                      preparation of this article. His assistance is gratefully
                      acknowledge.
                    </td>
                  </tr>
                  <tr>
                    <td>APF, Fondo di Vienna in Coll. Hib.</td>
                    <td>
                      Benignus Millett (ed.), ‘Calendar of Irish material in
                      vols 12 and 13 (ff 1-200) of the Fondo di Vienna in the
                      Propaganda Archives’ in Coll. Hib., xxiv (1982), pp 45-80;
                      ‘Vol. 13, Part 2, ff 201-401’ in Coll. Hib., xxv (1983),
                      pp 30-62; ‘Vol. 13, Part 3, ff 402-522’ in Coll. Hib.,
                      xxvi (1984), pp 20-45; id., ‘Calendar of volume 14 of the
                      Fondo di Vienna in the Propaganda Archives, 3 parts’ in:
                      Hib., xxix (1987), pp 34-58; xxx (1988), pp 26-54; id.,
                      ‘Calendar of volume 15 of the Fondo di Vienna in the
                      Propaganda Archives’ in Coll. Hib., xxxiii (1991), pp
                      54-92; id., ‘Calendar of volume 16 of the Fondo di Vienna
                      in the Propaganda Archives’ in Coll. Hib., xxxviii (1997),
                      59-81; ‘Vol. 16, Part 2, ff. 103-216’ in: Coll. Hib.,
                      xxxix-xl (1997-98), pp 96-105; ‘Vol. 16, Part 3, ff.
                      217-80’ in Coll. Hib., xli (2000), pp 10-35; ‘Vol. 16,
                      Part 4, ff. 281-371’ in Coll. Hib., xliii (2001), pp
                      13-33.
                    </td>
                  </tr>
                  <tr>
                    <td> APF, Lettere antiche, 138</td>
                    <td>
                      {' '}
                      report IPC Leuven 1639, names listed in ISUL, p. 80, n26.
                    </td>
                  </tr>
                  <tr>
                    <td>APF, SC</td>
                    <td>Scritturi riferite nei Congressi</td>
                  </tr>
                  <tr>
                    <td>APF, SC, CV, 49</td>
                    <td>
                      Collegi Vari, 49, Olandesi ed Irlandesi di Lovanio
                      (1622-1802) (microfilm in possession of Prof Jan
                      Roegiers).
                    </td>
                  </tr>
                  <tr>
                    <td>APF, SC, VC, 36</td>
                    <td>
                      Visite e Collegi, 36, Visite del Collegio Germa-nico e
                      altri Oltramarini e Oltramontani 1697-98.
                    </td>
                  </tr>
                  <tr>
                    <td>APF, SC, Irlanda, 1 in Coll. Hib.</td>
                    <td>
                      Benignus Millett (ed.), ‘Calendar of volume 1 (1625-68) of
                      the collection Scritture riferite nei congressi, Irlanda,
                      in Propaganda Archives’ in Coll. Hib., vi-vii (1963/4), pp
                      18-211.
                    </td>
                  </tr>
                  <tr>
                    <td>APF, SC, Irlanda, 2 in Coll. Hib.</td>
                    <td>
                      Benignus Millett (ed.), ‘Calendar of Volume 2 (1669-71) of
                      the Scritture riferite nei congressi, Irlanda in
                      Propaganda Archives: Part 1, ff 1-401’ in Coll. Hib., xvi
                      (1973-4), pp 7-47; ‘Part 2, ff 402-803’ in Coll. Hib.,
                      xvii (1974-5), pp 17-68.
                    </td>
                  </tr>
                  <tr>
                    <td>APF, SC, Irlanda, 3 in Coll. Hib.</td>
                    <td>
                      Benignus Millett (ed.), ‘Calendar of volume 3 (1672-5) of
                      Scriture refirite nei congressi, Ir-landa, in Propaganda
                      Archives Part 1, ff 1-200’ in Coll. Hib., xviii-xix
                      (1976-77), pp 40-71; ‘Part 2, ff 201-518’ in Coll. Hib.,
                      xxi-xxii (1979-80), pp 7-81.
                    </td>
                  </tr>
                  <tr>
                    <td>APF, SORCG </td>
                    <td>
                      Scritture originali riferite nelle congregazioni generali
                    </td>
                  </tr>
                  <tr>
                    <td>APF, SORCG 101 in Coll. Hib.</td>
                    <td>
                      Benignus Millett (ed.), ‘Catalogue of Irish material in
                      fourteen volumes of the Scritture originali riferite nelle
                      congregazioni generali in Propaganda Archives’ in Coll.
                      Hib. , x (1967), pp 7-59.
                    </td>
                  </tr>
                  <tr>
                    <td>APF, SORCG 129-131 in Coll. Hib. </td>
                    <td>
                      Benignus Millett (ed.), ‘Catalogue of Irish material in
                      vols. 129-131 of the Scritture originali riferite nelle
                      congregazioni generali in Propaganda Archives’ in Coll.
                      Hib., xi (1968-9), pp 7-18.
                    </td>
                  </tr>
                  <tr>
                    <td>APF, SORCG 132-139 in Coll. Hib.</td>
                    <td>
                      Benignus Millett (ed.), ‘Catalogue of Irish material in
                      vols. 132-139 of the Scritture originali riferite nelle
                      congregazioni generali in Propaganda Archives’ in Coll.
                      Hib., xii (1969), pp 7-44.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>APF, SORCG 140-3 in Coll. Hib.</td>
                    <td>
                      Benignus Millett (ed.), ‘Catalogue of Irish material in
                      vols. 140-3 of the Scritture originali riferite nelle
                      congregazioni generali in Propaganda Archives’ in Coll.
                      Hib., xiii (1971), pp 21-60.
                    </td>
                  </tr>
                  <tr>
                    <td>APF, SORCG 294 in Coll. Hib.</td>
                    <td>
                      Benignus Millett (ed.), ‘Catalogue of volume 294 of the
                      Scritture originali riferite nelle congregazioni generali
                      in Propaganda Archives’ in Coll. Hib., viii (1965), pp
                      7-37.
                    </td>
                  </tr>
                  <tr>
                    <td>APF, SORCG 370-371 in Coll. Hib.</td>
                    <td>
                      Benignus Millett (ed.), ‘Catalogue of Irish material in
                      vols. 370 and 371 of the Scritture originali riferite
                      nelle congregazioni generali in Propaganda Archives’ in
                      Coll. Hib., xxvii-xxviii (1985-86), pp 44-85.
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">APF, SORCG 406</td>
                  </tr>
                  <tr>
                    <td colSpan="2">APF, SORCG 850</td>
                  </tr>
                  <tr>
                    <td colSpan="2">APF, SORCG 862 </td>
                  </tr>
                  <tr>
                    <td colSpan="2">APF, SORCG 895</td>
                  </tr>
                  <tr>
                    <td colSpan="2">APF, SORCG 901</td>
                  </tr>
                  <tr>
                    <td>ARA</td>
                    <td>
                      Algemeen Rijksarchief (General State Archives), Brussels.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>ARA, GR </td>
                    <td>Geheime Raad (Privy Council).</td>
                  </tr>
                  <tr>
                    <td>ARA, GR 1088A</td>
                    <td>Burse Thomas Stapleton: (1760-70s).</td>
                  </tr>
                  <tr>
                    <td>ARA, GR 1088B</td>
                    <td>Burse Thomas Stapleton.</td>
                  </tr>
                  <tr>
                    <td>ARA, GR 1089B</td>
                    <td>Burse Thomas Stapleton.</td>
                  </tr>
                  <tr>
                    <td>ARA, SA</td>
                    <td>Staat en Audiëntie (State and Audience). </td>
                  </tr>
                  <tr>
                    <td>ARA, SA 2636</td>
                    <td>Requests and petitions, 1607. </td>
                  </tr>
                  <tr>
                    <td>ARA, SA 2637</td>
                    <td>Requests and petitions, 1608. </td>
                  </tr>
                  <tr>
                    <td>ARA, SA 2638</td>
                    <td>Requests and petitions, 1608. </td>
                  </tr>
                  <tr>
                    <td>ARA, SA 2639</td>
                    <td>Requests and petitions, 1609. </td>
                  </tr>
                  <tr>
                    <td>ARA, SA 2644</td>
                    <td>Requests and petitions, 1614. </td>
                  </tr>
                  <tr>
                    <td>ARA, SA 2645</td>
                    <td>Requests and petitions, 1615. </td>
                  </tr>
                  <tr>
                    <td>ARA, SA 2646</td>
                    <td>Requests and petitions, 1616. </td>
                  </tr>
                  <tr>
                    <td>ARA, SA 2647</td>
                    <td>Requests and petitions, 1617.</td>
                  </tr>
                  <tr>
                    <td>ARA, SSO </td>
                    <td>
                      Secretariaat van Staat en Oorlog (Secretary of State and
                      War).
                    </td>
                  </tr>
                  <tr>
                    <td>ARA, SSO 1078-1079 </td>
                    <td>
                      Correspondence of Count de Cobenzl. See Xavier Duquenne,
                      Inventaire analytique de la correspondance générale du
                      Comte de Cobenzl (1718-1770: Archives générales du
                      Royaume, Secrétairerie d’Etat et de Guerre, nrs 1053-1296
                      et 1299-1303), (Brussels, 2004).
                    </td>
                  </tr>
                  <tr>
                    <td>Arch. Hib.</td>
                    <td>
                      Archivium Hibernicum: or Irish Historical Records.
                      (Maynooth, 1912- ).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>AVB, NF</td>
                    <td>
                      Analecta Vaticano-Belgica, 2nd series A: Nonciature de
                      Flandre, Brussels-Rome.
                    </td>
                  </tr>
                  <tr>
                    <td>AVB, NF V</td>
                    <td>
                      B. de Meester (ed.), Correspondance du nonce
                      Giovanni-Francesco Guido di Bagno (1621--1627) Première
                      partie (1621-1624) in Analecta Vaticano-Belgica, 2nd
                      series A: Nonciature de Flandre V, (Brussels-Rome, 1938).
                    </td>
                  </tr>
                  <tr>
                    <td>AVB, NF X</td>
                    <td>
                      W. Brulez (ed.), Correspondance de Richard Pauli-Stravius,
                      1634-1642 in Analecta Vatican-o-Belgica, 2nd series A:
                      Nonciature de Flandre X, (Brussels-Rome, 1955).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>AVB, NF XI</td>
                    <td>
                      L. Van Meerbeeck (ed.), Correspondance du nonce Fabio de
                      Lagonissa, Archevèque de Conza (1627-1634) in Analecta
                      Vaticano-Belgica, 2nd series, Nonciature de Flandre XI,
                      (Brussels-Rome, 1966).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>BA</td>
                    <td>
                      Determinatio or Baccalaureus Artium; Baccalaureate of
                      Arts.
                    </td>
                  </tr>
                  <tr>
                    <td>Bax, Hiberni</td>
                    <td>
                      J.L. Bax, Hiberni Promoti, doctores, professores in Univ.
                      Lovan. - Collegia Hib. Lovain. & Antwerp. Brussels, KBR,
                      Mss. 22181.
                    </td>
                  </tr>
                  <tr>
                    <td>Bax, Historia</td>
                    <td>
                      J.L. Bax, Historia Universitatis Lovaniensis, Brussels,
                      KBR, Mss. 22172, 11 vols.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Begley </td>
                    <td>
                      John Archdeacon Begley, The diocese of Limerick from 1691
                      to the present time, (3 vols, Dublin, 1938).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Bellesheim </td>
                    <td>
                      A. Bellesheim, Geschichte der katholischen Kirche in
                      Irland, (3 vols., Mainz, 1890-1).
                    </td>
                  </tr>
                  <tr>
                    <td>Bergé, Kerkelijk leven Deinze</td>
                    <td>
                      Katrien Bergé, Kerkelijk leven in de landelijke dekenij
                      Deinze (1661-1762), Belgisch centrum voor landelijke
                      geschiedenis. Publ. 63, (Leuven, 1981).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Boute (2003) </td>
                    <td>
                      Bruno Boute, Academic Interests and Catholic
                      Confessionalisation in the Archducal Netherlands
                      (1598-1621). The Louvain Privileges of Nomination to
                      Ecclesiastical Benefices, Ph.D, Leuven, 2003.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Boyle, I.C.P. </td>
                    <td>
                      Patrick Boyle, The Irish college at Paris, 1578-1901,
                      (London, 1901).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Bp.</td>
                    <td>Bishop </td>
                  </tr>
                  <tr>
                    <td>Brady </td>
                    <td>
                      W. Maziere Brady, The episcopal succession in England,
                      Scotland and Ireland A.D. 1400 to 1875, …, (3 vols, Rome,
                      1876-77).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Brants (1917)</td>
                    <td>
                      Victor Brants, La Faculté de droit de l’Université de
                      Louvain à travers cinq siècles: étude historique,
                      (Brussels, 1917).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Brockliss (1987)</td>
                    <td>
                      Laurence W.B Brockliss and P. Ferté, ‘Irish Clerics in
                      France in the Seventheenth and Eighteenth Centuries: a
                      Statistical Study’ in Proceedings of the Royal Irish
                      Academy, 87, C, 9 (1987), pp 527-572.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Brockliss & Ferté, Arch. Hib. LVIII (2004) </td>
                    <td>
                      Laurence W.B Brockliss and P. Ferté, ‘Irish Clerics in
                      France in the Seventheenth and Eighteenth Centuries: a
                      Statistical Study’ in Proceedings of the Royal Irish
                      Academy, 87, C, 9 (1987), pp 527-572.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>BRUNEEL (1977) </td>
                    <td>
                      Claude Bruneel, Répertoire des thèses imprimées de
                      lUniversité de Louvain (1425-1797). 1: Faculté de
                      médecine. Fonds de la Bibliothèque centrale. UCL.
                      Catalogues, inventaires et répertoires de la Bibliothèque
                      centrale, 3, (Louvain, Université Catholique de Louvain,
                      1977).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>BRUNEEL (2004)</td>
                    <td>
                      Claude Bruneel & Claude de Moreau de Gerbehaye, Les
                      gradués de la Faculté de médecine de lancienne Université
                      de Louvain (XVIe-XVIIe siècles) in Académie royale de
                      Belgique. Commission royale dhistoire. Publications, 74,
                      (Brussels, Commission royale d’histoire, 2004).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Callewier (2005) </td>
                    <td>
                      Hendrik Callewier, Inventaris van het oud archief van de
                      Sint-Jacobskerk te Leuven (1273-1803), Rijksarchief te
                      Leuven, Inventarissen 42, (Brussel, 2005).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Card.</td>
                    <td> Cardinal</td>
                  </tr>
                  <tr>
                    <td>Cassiman, Jan Barry</td>
                    <td>
                      A. Cassiman, ‘Toen Jan Barry pasterde te Deinze’ in
                      K.O.K.-Deinze, ii (1930).
                    </td>
                  </tr>
                  <tr>
                    <td>CC</td>
                    <td>Curate</td>
                  </tr>
                  <tr>
                    <td>Clergy Derry</td>
                    <td>
                      Edward Daly & Kieran Devlin, The Clergy of the Diocese of
                      Derry: an Index (Dublin, 1997).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Cloet,</td>
                    <td>Michel Cloet, ‘Het Sint-Blasiushospitaal te</td>
                  </tr>
                  <tr>
                    <td>Sint-Blasiushospitaal</td>
                    <td>
                      Deinze in de 17de en 18de eeuw’ in Bijdragen tot de
                      geschiedenis van Deinze en de Leiestreek, lxxx (2003), pp
                      153-220.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Clogher Rec.</td>
                    <td>Clogher Record</td>
                  </tr>
                  <tr>
                    <td>Cm</td>
                    <td>Collegium minus; Small College of the Holy Spirit.</td>
                  </tr>
                  <tr>
                    <td>CM</td>
                    <td>Collegium minus; Great College of the Holy Spirit.</td>
                  </tr>
                  <tr>
                    <td>Coll.</td>
                    <td>College</td>
                  </tr>
                  <tr>
                    <td>Collat.</td>
                    <td>Collation of a burse </td>
                  </tr>
                  <tr>
                    <td>Coll. Hib.</td>
                    <td>Collectanea Hibernica </td>
                  </tr>
                  <tr>
                    <td>Cregan (1979)</td>
                    <td>
                      Donal F. Cregan, ‘The Social and Cultural Background of a
                      Counter-Reformation Episcopate, 1618-1660’ in Art Cosgrave
                      and Donal McCartney (eds.), Studies in Irish History.
                      Presented to R. Dudley Edwards, (Dublin, 1979), pp 85-117.
                    </td>
                  </tr>
                  <tr>
                    <td>D</td>
                    <td>Deacon</td>
                  </tr>
                  <tr>
                    <td>DDA, AB1/116/2</td>
                    <td>Dublin Diocesan Archives, AB1/116/2</td>
                  </tr>
                  <tr>
                    <td>Def. Form. </td>
                    <td>Fac Arts: Defenderunt formaliter.</td>
                  </tr>
                  <tr>
                    <td>Det.</td>
                    <td>Fac. Arts: Determinarunt, determinavit.</td>
                  </tr>
                  <tr>
                    <td>Diocese Elphin </td>
                    <td>
                      Francis Beirne (ed.), The Diocese of Elphin: People,
                      Places and Pilgrimage, (Dublin, 2000).
                    </td>
                  </tr>
                  <tr>
                    <td>Diocese Ferns </td>
                    <td>
                      John V. Gahan, The secular priests of the Diocese of
                      Ferns: lists of priests who served in each parish, with
                      short biographical notes, (Strasbourg, 2000).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Diocese Kilmore</td>
                    <td>
                      Francis J. MacKiernan, Diocese of Kilmore, bishops and
                      priests 1136-1988, (Cavan, 1989) (repr. 1990).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Diocese Meath, 18th </td>
                    <td>
                      Patrick Fagan, The Diocese of Meath in the Eighteenth
                      Century, (Dublin, 2001).
                    </td>
                  </tr>
                  <tr>
                    <td>Doc. IPC Leuven </td>
                    <td>
                      F.M. Jones, ‘Documents concerning the Collegium Pastorale
                      Hibernicum of Louvain, 1624’ in Arch. Hib., xvi (1951), pp
                      40-62.
                    </td>
                  </tr>
                  <tr>
                    <td>Fam.</td>
                    <td>Family</td>
                  </tr>
                  <tr>
                    <td>Fenning, IDL 1700 </td>
                    <td>
                      Hugh Fenning, ‘Irish Dominicans at Louvain before 1700: a
                      Biographical Register’, Coll. Hib., xliii (2001), pp
                      112-160.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Fenning, IOR </td>
                    <td>
                      Hugh Fenning, ‘Irishmen ordained at Rome, 1572-1697’ in
                      Arch. Hib., lix (2005), pp 1-36.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>French (1846) </td>
                    <td>
                      The historical works of the Right Reverend Nicholas
                      French, D.D., bishop of Ferns, ed. James Duffy, (Dublin,
                      1846).
                    </td>
                  </tr>
                  <tr>
                    <td>Fund. </td>
                    <td>Fundator/Foundation (of a scholarship or burse)</td>
                  </tr>
                  <tr>
                    <td>Hellin </td>
                    <td>
                      [E.A. Hellin], Histoire chronologique des evêques, et du
                      chapitre exemt de l’eglise cathédrale de S. Bavon à Gand;
                      … , à Gand, chez Pierre de Goesin, 1772. Supplement… ,
                      (Gand, 1777).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>IB </td>
                    <td>
                      Joseph P. Spelman, ‘The Irish in Belgium’, in I.E.R., vi
                      (1885), vii (1886), ix (1888).
                    </td>
                  </tr>
                  <tr>
                    <td>IDC </td>
                    <td>Irish Dominican College, Leuven.</td>
                  </tr>
                  <tr>
                    <td>I.E.R. </td>
                    <td>Irish Ecclesiastical Record </td>
                  </tr>
                  <tr>
                    <td>IFC </td>
                    <td>Irish Franciscan College, Leuven. </td>
                  </tr>
                  <tr>
                    <td>IPC </td>
                    <td>Irish Pastoral College, Leuven. </td>
                  </tr>
                  <tr>
                    <td>Irish Books</td>
                    <td>
                      M.O. Walsh, Irish Books printed abroad, 1475-1700, (The
                      Irish Book, 2/1) (Dublin, 1963).
                    </td>
                  </tr>
                  <tr>
                    <td>ISUL = (J) </td>
                    <td>
                      Brendan Jennings, ‘Irish students in the University of
                      Louvain’ in Sylvester O’Brien (ed.), Measgra i gCuimhne
                      Mhichíl Uí Chléirigh, (Dublin, 1944), pp 74-97.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>J.W.S.E.I.A.S.</td>
                    <td>
                      Journal of the Waterford and South-East of Ireland
                      Archaeological Society{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Jennings, Acta SCPF</td>
                    <td>
                      Brendan Jennings, ‘Acta Sacra Congregationis de Propaganda
                      Fide, 1622-1650’ in Arch. Hib., xxii (1959), pp 28-140.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Jennings, IPM</td>
                    <td>
                      Brendan Jennings, ‘Irish Preachers and Confessors in the
                      Archdiocese of Malines, 1607-1794’ in Arch. Hib., xxiii
                      (1960), pp 148-66.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Jennings, M. Doc. </td>
                    <td>
                      Brendan Jennings, ‘Miscellaneous Documents I, 1588-1634’
                      in Arch. Hib., xii (1946), pp 160-62; Id., ‘Miscellaneous
                      Documents II, 1625-1640’ in: Arch. Hib., xiv (1949), pp
                      1-49; Id., ‘Miscellaneous Documents III, 1602-1715’ in
                      Arch. Hib., xv (1950), pp 1-74.
                    </td>
                  </tr>
                  <tr>
                    <td>Jennings, MOR</td>
                    <td>
                      Brendan Jennings, ‘Irish Names in the Malines Ordination
                      Registers 1602-1794’ in I.E.R., lxxxv (1951), pp 149-62,
                      lxxvi (1951), pp 44-48, 128-40, pp 222-33, pp 314-18, pp
                      399-408, pp 483-87, lxxvii (1952), pp 202-07, pp 366-69.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Jennings, Reports</td>
                    <td>
                      Brendan Jennings (ed.), ‘Reports on Irish Colleges in the
                      Low Countries, 1645-1700’ in Arch. Hib., xvi (1951), pp
                      1-39.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Jennings, Wild Geese</td>
                    <td>
                      Brendan Jennings (ed.), Wild Geese in Spanish Flanders,
                      1582-1700, (Dublin, 1964){' '}
                    </td>
                  </tr>
                  <tr>
                    <td>JUB </td>
                    <td>Juris Utriusque Baccalaureus </td>
                  </tr>
                  <tr>
                    <td>JUD </td>
                    <td>Juris Utriusque Doctor </td>
                  </tr>
                  <tr>
                    <td>JUL</td>
                    <td>Juris Utriusque Licentiatus </td>
                  </tr>
                  <tr>
                    <td>Jus</td>
                    <td>Juris Utriusque studiosus </td>
                  </tr>
                  <tr>
                    <td>Kent 1742 </td>
                    <td>
                      Kent 1742 Hugh Fenning, ‘John Kent’s Report on the Irish
                      Mission, 1742’ in Arch. Hib., xxxi (1966), pp 65-87.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>KBR </td>
                    <td>
                      Brussels, Koninklijke Bibliotheek/Bibliothèque royale
                      (Royal Library){' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Kerry (1985) </td>
                    <td>
                      Pádraig de Brún, ‘Some lists of Kerry priests, 1750-1835’
                      in Kerry Archaeological and Historical Society Journal,
                      xviii (1985), pp 83-169.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Kuborn </td>
                    <td>
                      E. Kuborn, Les fondations de bourses d’études. Loi.
                      Arretés royaux. Circulaire ministérielle. Renseignements
                      divers. Notices sur toutes les fondations administrées par
                      la Commission provinciale du Brabant, (2 vols, Brussels,
                      1900?).
                    </td>
                  </tr>
                  <tr>
                    <td>KUL </td>
                    <td>
                      Katholieke Universiteit Leuven (Catholic University
                      Leuven){' '}
                    </td>
                  </tr>
                  <tr>
                    <td>KUL, U.A. </td>
                    <td>Universiteitsarchief (University Archives) </td>
                  </tr>
                  <tr>
                    <td>KUL, U.A., OUL </td>
                    <td>Oude Universiteit Leuven (Old University Leuven)</td>
                  </tr>
                  <tr>
                    <td>KUL, U.A., OUL Unigenitus </td>
                    <td>
                      Signatures on the Bull Unigenitus in Fac. Canon Law
                      (formerly Bibliotheca Bollandiana, Brussels, Ms. 273){' '}
                    </td>
                  </tr>
                  <tr>
                    <td>KUL, U.A., OUL 43 </td>
                    <td>
                      Fund J.O’Brien 19 Mar.-11 Aug. 1764 and 6 Oct. 1767{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>KUL, U.A., OUL 192 </td>
                    <td>Fund. John Sinnich</td>
                  </tr>
                  <tr>
                    <td>KUL, U.A., OUL 196 </td>
                    <td>Fund. John Sinnich </td>
                  </tr>
                  <tr>
                    <td>KUL, U.A., OUL 320-340 </td>
                    <td>Bursae: state of 1784-98 </td>
                  </tr>
                  <tr>
                    <td>KUL, U.A., OUL 320c/82</td>
                    <td>
                      Fundationes volantes, 1750-1800: nr. 82: Michael Hennessy{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>KUL, U.A., OUL 320d/143 </td>
                    <td>
                      Fundationes volantes, 1750-1800: nr. 143: Thomas Stapleton{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>KUL, U.A., OUL 333 </td>
                    <td>
                      Fund. Thomas Stapleton: law suit by Edmond Stapleton
                      against Richard Power; Fund. Fl. Sullivan{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>LA </td>
                    <td>Promotion in the Arts; Licentiate of Arts</td>
                  </tr>
                  <tr>
                    <td> PL</td>
                    <td>Postlineales</td>
                  </tr>
                  <tr>
                    <td> PM</td>
                    <td>Postmediales </td>
                  </tr>
                  <tr>
                    <td>Laenen (1921)</td>
                    <td>
                      Joseph Laenen, Het Iersch college te Antwerpen: lezing
                      gehouden op de feestzitting van Antwerpen’s
                      oudheidkundigen kring, ten stadhuize, den 30 October 1921,
                      (Baasrode, 1923). Reprint from Bijdragen tot de
                      geschiedenis, xiv (1922-23). For English language version
                      see Nilis (1996).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>LP (1968) </td>
                    <td>
                      Brendan Jennings, Cathaldus Giblin (eds), Louvain papers
                      1606-1827, (Dublin, 1968).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>MA </td>
                    <td>
                      Magister Artium; Master of Arts; Birredatus in Fac. Artium{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Matr.: </td>
                    <td>Matriculated</td>
                  </tr>
                  <tr>
                    <td>Matr.: (C) </td>
                    <td>Pedagogy the Castle (Castrum)</td>
                  </tr>
                  <tr>
                    <td>Matr.: (F) </td>
                    <td>Pedagogy the Falcon (Falco) </td>
                  </tr>
                  <tr>
                    <td>Matr.: (L) </td>
                    <td>Pedagogy the Lily (Lilium) </td>
                  </tr>
                  <tr>
                    <td>Matr.: (P) </td>
                    <td>Pedagogy the Pig (Porcus)</td>
                  </tr>
                  <tr>
                    <td>Meulemans, Huizen... Oude Leuven </td>
                    <td>
                      Alfons Meulemans, Huizen en straten van het Oude Leuven,
                      Jaarboek Leuvens Historisch Genootschap, xlii, (Leuven,
                      2004).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>M </td>
                    <td>Minor Orders</td>
                  </tr>
                  <tr>
                    <td>MB </td>
                    <td>Medicinae Baccalaureus</td>
                  </tr>
                  <tr>
                    <td>MD </td>
                    <td>Medicinae Doctor</td>
                  </tr>
                  <tr>
                    <td>ML </td>
                    <td>Medicinae Licentiatus </td>
                  </tr>
                  <tr>
                    <td>Ms </td>
                    <td>Medicinae studiosus; student in Medicine. </td>
                  </tr>
                  <tr>
                    <td>MS 1085 </td>
                    <td>
                      Rheims, Archives Municipale MS 1085: list of degrees at
                      Faculty of Medicine at the University of Rheims. This list
                      was examined by L.W.B. Brockliss for Irish students. The
                      author is grateful for the use of Prof Brockliss’s notes.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>MUL </td>
                    <td>
                      E. Reussens, J. Wils and A. Schillings (eds), Matriculé de
                      l’Université de Louvain, (10 vols, Brussels, 1903-80).
                    </td>
                  </tr>
                  <tr>
                    <td>NF in: Coll. Hib.</td>
                    <td>
                      C. Giblin, ‘Catalogue of Material of Irish Interest in the
                      Collection Nunziatura di Fiandra’ in Coll. Hib., i (1958);
                      iii (1960); iv (1961); v (1962); ix (1966); x (1967); xi
                      (1968); xii (1969); xiii (1970); xiv (1971); xv (1972).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Nilis (1996) </td>
                    <td>
                      Jeroen Nilis and Joseph Laenen, ‘The Irish College
                      Antwerp’ in Clogher Record, xv, 3 (1996), pp 7-86.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Nom. </td>
                    <td>Nominated</td>
                  </tr>
                  <tr>
                    <td>Nuttinck(1969)</td>
                    <td>
                      Michel Nuttinck, La vie et loeuvre de Zeger-Bernard Van
                      Espen, un canoniste janséniste, gallican et régalien à
                      lUniversité de Louvain (1646-1728)’ in UCL. Recueil de
                      travaux dhistoire et de philologie, 43 (Louvain, 1969).
                    </td>
                  </tr>
                  <tr>
                    <td>O’Fiaich, Poets </td>
                    <td>
                      Cardinal Tomas O’Fiaich, ‘Poets and scholars of Creggan
                      parish’ in Creggan: Journal of the Creggan Local History
                      Society, I (1986) pp{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>O’Flanders (1922) </td>
                    <td>A. O’Flanders, Erin, (Nieuwpoort, 1922). </td>
                  </tr>
                  <tr>
                    <td>Oath 1782 </td>
                    <td>
                      Reginald Walsh, ‘A list of Ecclesiastics that took the
                      Oath of Allegiance’ in I.E.R., xxx (1882), pp 46-76.
                    </td>
                  </tr>
                  <tr>
                    <td>O.F.M. </td>
                    <td>Ordinis Fratrum Minorum; Franciscan</td>
                  </tr>
                  <tr>
                    <td>O.F.M. Cap.</td>
                    <td>Ordinis Fratrum Minorum Capucinorum; Capucin </td>
                  </tr>
                  <tr>
                    <td>O.P. </td>
                    <td>Ordinis Praedicatorum; Dominican </td>
                  </tr>
                  <tr>
                    <td>Ord. </td>
                    <td>Ordination(s) </td>
                  </tr>
                  <tr>
                    <td>PA </td>
                    <td>Patres Augustiniani </td>
                  </tr>
                  <tr>
                    <td>PBA </td>
                    <td>Presentatio Baccalaureorum Artium </td>
                  </tr>
                  <tr>
                    <td>Pbr </td>
                    <td>Presbyter; Priest </td>
                  </tr>
                  <tr>
                    <td>PLA </td>
                    <td>Presentatio Magister Artium (= Licentiatus Artium) </td>
                  </tr>
                  <tr>
                    <td>Ped. </td>
                    <td>Pedagogy</td>
                  </tr>
                  <tr>
                    <td>P. Ó Suilleabhain, Library </td>
                    <td>
                      Padraig Ó Suilleabhain, ‘The library of a parish priest of
                      the Penal Days’ in Coll. Hib., vi-vii (1963-4), pp
                      234-244.
                    </td>
                  </tr>
                  <tr>
                    <td>Power, Waterford (1937) </td>
                    <td>
                      Patrick Power, Waterford and Lismore: a compendious
                      history of the united dioceses, (Cork, 1937).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>P.P. </td>
                    <td>Parish Priest</td>
                  </tr>
                  <tr>
                    <td>Pr. </td>
                    <td>Priest</td>
                  </tr>
                  <tr>
                    <td>Pr.A. </td>
                    <td>Promotion in the Arts = LA</td>
                  </tr>
                  <tr>
                    <td>Pres. </td>
                    <td>President</td>
                  </tr>
                  <tr>
                    <td>Proost, refugiés </td>
                    <td>
                      J.J.E. Proost, ‘Les refugiés Anglais et Irlandais en
                      Belgique à la suite de la réforme religieuse établie sous
                      Elisabeth en Jacques Ier’ in Messager des sciences
                      historiques ou archives des arts et de la bibliographie
                      (1865), pp 277-314.
                    </td>
                  </tr>
                  <tr>
                    <td>Publ. </td>
                    <td>Published; publications.</td>
                  </tr>
                  <tr>
                    <td>Quaghebeur, Pro aris </td>
                    <td>
                      Toon Quaghebeur, Pro aris et focis. Theologie en macht aan
                      de Theologische Faculteit te Leuven 1617-1730, Ph.D
                      thesis, 2 vols, Leuven, 2004.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAG</td>
                    <td>Rijksarchief Gent (State Archives, Ghent).</td>
                  </tr>
                  <tr>
                    <td>RAG, B1437; B4916-4922</td>
                    <td>Diocese of Ghent, ordination registers.</td>
                  </tr>
                  <tr>
                    <td>RAL</td>
                    <td>Rijksarchief Leuven (State Archives Leuven).</td>
                  </tr>
                  <tr>
                    <td>RAL, OUL </td>
                    <td>
                      Oude Universiteit Leuven (Old University Leuven) (formerly
                      at ARA) See Henri De Vocht, Inventaire des archives de
                      l’Université de Louvain 1426-1797 aux Archives générales
                      du Royaume à Bruxelles, (Louvain, 1927).
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 24</td>
                    <td>
                      Liber quartus Intitulatorum, 28 Feb.1528-28 Feb. 1569.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 25</td>
                    <td>
                      Liber sextus Intitulatorum, 19 Feb. 1616-3 Feb. 1651.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 26</td>
                    <td>
                      Liber septimus Intitulatorum, 3 Feb. 1651-31 Aug.1683.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 128</td>
                    <td>
                      List of all Licentiates of the University of Leuven in
                      Theology, Law and Medicine: from 2 May 1773 to 1 July
                      1788; list of all Primi at the General Promotion in the
                      Arts, 1428-1793.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 161</td>
                    <td>Fundationes Universitatis Lovaniensis, part I. </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 162</td>
                    <td>Fundationes Universitatis Lovaniensis, part II. </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 273</td>
                    <td>
                      Liber Computuum Receptorum Universitatis, 21 Dec. 1529-21
                      Dec.1543.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 274</td>
                    <td>
                      Liber Computuum Receptorum Universitatis, 21 Dec. 1543-24
                      June 1556.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 275</td>
                    <td>
                      Liber Computuum Receptorum Universitatis, 21 Dec. 1621-24
                      June 1637.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 276</td>
                    <td>
                      Liber Computuum Receptorum Universitatis, 24 June 1637-24
                      June 1654.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 277</td>
                    <td>
                      Liber Computuum Receptorum Universitatis, 21 Dec. 1659-24
                      June 1669.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 278</td>
                    <td>
                      Liber Computuum Receptorum Universitatis, 24 June 1669-21
                      Dec. 1679.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 279</td>
                    <td>
                      Liber Computuum Receptorum Universitatis, 21 Dec. 1679-24
                      June 1690.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 280</td>
                    <td>
                      Liber Computuum Receptorum Universitatis, 24 June 1690-21
                      Dec. 1702.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 281</td>
                    <td>
                      Liber Computuum Receptorum Universitatis, 28 Jan. 1703-31
                      Aug. 1722.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 282</td>
                    <td>
                      Liber Computuum Receptorum Universitatis, 28 Feb. 1723-28
                      Feb. 1738.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 283</td>
                    <td>
                      Liber Computuum Receptorum Universitatis, 28 Feb. 1738-29
                      Feb. 1752.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 284</td>
                    <td>
                      Liber Computuum Receptorum Universitatis, 26 Feb. 1752-28
                      Feb. 1765.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 285</td>
                    <td>
                      Liber Computuum Receptorum Universitatis, 28 Feb. 1765-27
                      Feb. 1776.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 286</td>
                    <td>
                      Liber Computuum Receptorum Universitatis, 27 Feb. 1776-30
                      June 1788.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 287</td>
                    <td>Accounts of the University, Oct. 1788- Nov. 1789. </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 288</td>
                    <td>
                      Liber Computuum Receptorum Universitatis, 28 Feb. 1790-26
                      Oct. 1797.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 289</td>
                    <td>
                      Accounts of the Receptor of the University, books for the
                      audition: 11 Sept. 1711-28 Feb. 1722; 28 Feb. 1731-29 Feb.
                      1732.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 290</td>
                    <td>
                      Accounts of the Receptor of the University, books for the
                      audition: 28 Feb. 1738-30 Aug. 1752.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 291</td>
                    <td>
                      Accounts of the Receptor of the University, books for the
                      audition: 26 Feb. 1765-26 Feb. 1773.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 292</td>
                    <td>
                      Accounts of the Receptor of the University, 1674-1679,
                      with notes, minutes and framents of accounts.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 388</td>
                    <td>
                      Fac. Theology, Acta Facultatis S. Theologiae, 28 Feb.
                      1631-28 Feb. 1663.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 476</td>
                    <td>
                      Fac. Theology, list of signatures on the Bull Unigenitus
                      (1713), imposed by order of the University on anybody,
                      presenting himself for graduation, from 12 Feb. 1731-6 May
                      1794.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 505</td>
                    <td>
                      Fac. Theology, Nomina Baccalaureorum Biblicorum,
                      Sententiariorum et Formatorum [Licentiatorum et Doctorum]
                      a Ven. Facultate Theologiae in Universitate Lovaniensi
                      promotorum ab anno 1585 ad mensem Martium anni 1619 [Hand
                      of bedell Gerard Rivius].{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 506</td>
                    <td>
                      Fac. Theology, Responsiones Vacatiales: July 1600 – Feb.
                      1611.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 507</td>
                    <td>
                      Fac. Theology, Registrum Bedellorum G. Rivii et B. Masii:
                      sermons, lists of baccalaureates, licentiates, doctorates
                      and examiners: 23 Apr. 1605-20 Apr. 1635.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 508</td>
                    <td>
                      Fac. Theology, Acta Baccalaureorum et Responsiones
                      Formales, licentiates and doctorates, 1617-33, with
                      indication of the subjets and accounts of certain
                      professors: manual of G. Rivius.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 510</td>
                    <td>
                      Fac. Theology, Responsiones Vacatiales: 1662- Apr. 1682.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 511</td>
                    <td>
                      Fac. Theology, Register of bedell P. de Vaddere, exercises
                      and exams in theology, Oct. 1670-89.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 673</td>
                    <td>
                      Fac. Law, Liber Fiscalis Baccalaureorum Juris Utriusque
                      from Mar. 1594 to Sept. 1623.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 674</td>
                    <td>
                      Fac. Law, Liber Fiscalis Baccalaureorum Juris Utriusque
                      from 1687 to 1 Oct. 1797.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 713</td>
                    <td>
                      Fac. Arts, Acta Facultatis Artium, Liber nonus, 5 Jan.
                      1572-1 Feb. 1597.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 714</td>
                    <td>
                      Fac. Arts, Acta Facultatis Artium, Liber decimus, 18 Apr.
                      1597-30 Sept. 1613.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 715</td>
                    <td>
                      Fac. Arts, Acta Facultatis Artium, Liber undecimus, 1 Oct.
                      1613-2 June 1625.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 716</td>
                    <td>
                      Fac. Arts, Acta Facultatis Artium, Liber duodecimus, 1
                      June 1625-18 Jan. 1647.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 809</td>
                    <td>
                      Fac. Arts, Liber Biretatorum seu Doctorum Artium anni 1680
                      [-1764]: List of birretati, 1 Mar. 1680-7 June 1764; Actus
                      formalis, determination etc. Aug. 1676-1705.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 810</td>
                    <td>
                      Fac. Arts, Liber Birretatorum seu Doctorum Artium: List of
                      birretati, 23 June 1764- 9 June 1794.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 811</td>
                    <td>
                      Fac. Arts, Register of the Actus Licentiae in Artibus et
                      Promotiones from Nov. 1643 to Nov. 1764.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 811 bis.</td>
                    <td>
                      Fac. Arts, Register of Promotiones Generales in Artibus
                      universitatis Lovaniensis ab erectione ejusdem anno usque
                      ad praesens ex libris originalibus facultatis artium
                      collectae: 1429-1797.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 811/1</td>
                    <td>
                      Fac. Arts, Register of the Promotiones in Artibus:
                      1500-1659.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 811/2</td>
                    <td>
                      Fac. Arts, Register of the Promotiones in Artibus: 9 Nov.
                      1666-15 Nov. 1746.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 811/3</td>
                    <td>
                      Fac. Arts, Register of the Promotiones in Artibus: list of
                      Post-Mediales: 1744-1797{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 811/4</td>
                    <td>
                      Fac. Arts, Register of the Promotiones in Artibus et
                      Promotiones: Nov. 1643-Nov. 1764{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 812</td>
                    <td>
                      Fac. Arts, Register of Actus Licentiae in Artibus et
                      Promotiones: 1660-1743{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 813</td>
                    <td>
                      Fac. Arts, Liber Promotionum: 6 Nov. 1674-28 Oct. 1761
                      (complete lists){' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 814</td>
                    <td>Fac. Arts, Liber Promotionum: 1737-87; 1789-95.</td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 815</td>
                    <td>Fac. Arts, Liber Promotionum: 1758-93. </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 866</td>
                    <td>
                      Fac. Arts, Ped. Castrum (C), Liber Nominum commensalium
                      Castrensium: with names of students: Oct. 1762-76.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 924</td>
                    <td>
                      Fac. Arts, Ped. Castrum (C), accounts: with names of
                      students: 10 July 1575-30 Apr. 1617.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 925</td>
                    <td>
                      Fac. Arts, Ped. Castrum (C), accounts: with names of
                      students: 1617-1624.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1028</td>
                    <td>
                      Fac. Arts, Ped. Porcus (P), accounts: with names of
                      students: 1574; 1499-1598.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1029</td>
                    <td>
                      Fac. Arts, Ped. Porcus (P), accounts: with names of
                      students: 1599-1613.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1036</td>
                    <td>
                      Fac. Arts, Ped. Porcus (P), accounts: with names of
                      students: Manuale Philosophorum: 1 Oct. 1779-4 Oct. 1797.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1299</td>
                    <td>
                      Fac. Arts, Ped. Falco (F), Varia a.o. accounts: lists of
                      students: 1605-8.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1312</td>
                    <td>
                      Fac. Arts, Ped. Falco (F), accounts: 15 Sept. 1583-21 July
                      1587, with lists of students.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1313</td>
                    <td>
                      Fac. Arts, Ped. Falco (F), accounts: 4 June 1594-4 Sept.
                      1605, with lists of students.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1314</td>
                    <td>
                      Fac. Arts, Ped. Falco (F), accounts: 24 June 1621-24 June
                      1622, with lists of students.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1498</td>
                    <td>
                      CM, list of students with bursaries they enjoy, 1774-87.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1499</td>
                    <td>
                      CM, Registrum Alumnorum 1774-93 (held by J.F. van de Velde
                      and J.L. Bax).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1598</td>
                    <td>
                      CM, Manuale Alumnorum Majoris Collegii Sancti Spiritus:
                      manual held by the procurator (J.L. Bax), from 9 July
                      1785-88{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1599</td>
                    <td>
                      CM, Manuale Alumnorum: manuels held by the procurator
                      (J.L. Bax), Apr. 1790-94.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1605</td>
                    <td>
                      CM, Accounts of the College, with lists of the students,
                      24 Dec. 1684-23 Dec. 1691.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1606</td>
                    <td>
                      CM, Accounts of the College, with lists of the students, 1
                      June 1736-1 Oct. 1744.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1607</td>
                    <td>
                      CM, Accounts of the College, with lists of the students,
                      Copia computus D.ni Procuratoris Swijsen 1 June 1736-1
                      Oct. 1744.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1608</td>
                    <td>
                      CM, Accounts of the College, with lists of the students, 1
                      Oct. 1744-1 Oct. 1749.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1609</td>
                    <td>
                      CM, Accounts of the College, with lists of the students,
                      Copia 1 Oct. 1744-1 Oct. 1749.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1610/1</td>
                    <td>
                      CM, Accounts of the College, with lists of the students,
                      1758-1765.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1610/2</td>
                    <td>
                      CM, Accounts of the College, with lists of the students,
                      1758-1765.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1611</td>
                    <td>
                      CM, Accounts of the College, with lists of the students,
                      1765-1781.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1612</td>
                    <td>
                      CM, Accounts of the College, with lists of the students,
                      1792-1797.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1631-1634</td>
                    <td>CM, Registers Burses</td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1631</td>
                    <td>
                      CM, Liber Fundationum Majoris Collegii, renovatus 1713
                      with lists of the bursaries, 1713-85. Tomus Primus with
                      alphabetical list.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1632</td>
                    <td>
                      CM, Liber Fundationum Majoris Collegii, renovatus 1713
                      with lists of the bursaries, 1713-85. Tomus Secundus with
                      alphabetical list.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1633</td>
                    <td>
                      CM, Lists of Burses and their revenues with indication of
                      the collators, conditions etc. [by J.L. Bax, after 1786].{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1634</td>
                    <td>
                      CM, Lists and documents relating to this Burses: 1700-92,
                      with list of bursaries.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1635</td>
                    <td>
                      CM, Manuale Fundationum with list of bursaries, 1770-80.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1636</td>
                    <td>
                      CM, Manuale Fundationum with list of bursaries, 1780-87.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1637</td>
                    <td>
                      CM, Manuale Bursariorum Majoris Collegii inhabitantium in
                      eodem: with list of bursaries, 1754-88.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1638</td>
                    <td>
                      CM, Manuale Bursariorum Majoris Collegii inhabitantium
                      extra illud: with list of bursaries, 1732-88.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1639</td>
                    <td>
                      CM, Manuale Bursariorum et Fundationum with list of
                      bursaries, with financial and biographical notes, end 18th
                      century.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1747</td>
                    <td>
                      CM, Burse John Sinnich (Cork, Ireland), STD et Prof.,
                      pres. CM by will of 10 Apr. 1666: will, admin. general.
                      collat.: 1643-1794.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 1755</td>
                    <td>
                      CM, Burse Raymond Barry (Cork), P.P. Deinze (Belgium),
                      augm. of Burse John Sinnich, by will of 22 May 1687: will,
                      exec., admin.: 1687-1711.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 2148</td>
                    <td>
                      Standonck College: Burse (Cork, Ireland) by John Sinnich,
                      STD & Prof., pres. CM, by will dated 10 Apr. 1666: will,
                      agreement with CM, collat. 1666-1797.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 2708</td>
                    <td>
                      Popes College, Liber Fundationum Collegii Pontificis et
                      Bursariorum with lists of bursaries: 1625-39.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 3470</td>
                    <td>
                      Coll. Milius or de Luxemburg, visitation of; complaints of
                      its pres. William Fabricius.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 3471</td>
                    <td>
                      Coll. Milius or de Luxemburg: difficulties nomination
                      Martin Caddam as its president, by Francis Martin; law
                      suit 1704; 1721.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 3472</td>
                    <td>
                      Coll. Milius or de Luxemburg: on Thomas Stapleton,
                      president.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 3473</td>
                    <td>
                      Coll. Milius or de Luxemburg: on Maure Fabricius, JUL,
                      pres.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 3474</td>
                    <td>
                      Coll. Milius or de Luxemburg, pres. Martin Caddam, coll.
                      by Francis Martin.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 3475</td>
                    <td>
                      Coll. Milius or de Luxemburg, pres. Alard van der Steen.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 3705</td>
                    <td>IFC, Burse Raymond Magrath, by will 1 Mar. 1734. </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4148</td>
                    <td> IPC, divers docs. 1656, 1721, 1724-27, 1781.</td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4149</td>
                    <td>
                      IPC, ‘Burse’ Schinkel: difficulties between IPC and
                      pensionnaire de Louvain, Jean Schorenbroodt, who pretends
                      to be the heir ab intestato of Schinkel and claims the
                      property of the College: 1661.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4150</td>
                    <td>
                      IPC, pres. Florent O’Sullivan: docs regarding difficulties
                      with students: 1720-24.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4151</td>
                    <td>
                      IPC, pres. Florent O’Sullivan-Francis Martin: law suit for
                      bad management of the affairs and difficulties with
                      students: 1720-21.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4152</td>
                    <td>
                      IPC, pres. P. Macve: problems with his students, 1787-93,
                      difficulties regarding his succession, 1793.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4153</td>
                    <td>
                      IPC, doc. Rel. to students: difficulties regarding
                      discipline & observation of the rules, 1754 and 1787.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4154</td>
                    <td>
                      IPC, accounts of pres. John Kent and exec. of his will, 10
                      June 1773-16 Nov. 1778.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4155</td>
                    <td>
                      IPC, Burses by Pope Urbain VIII and Eugene Matthews
                      MacMahon, Abp. Dublin, in 1624: regulations{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4156</td>
                    <td>
                      IPC, Burse John Schinkel, STD and Prof., pres. Of Coll.
                      d’Arras and Adrian VI, by will of 25 Feb. 1637: will,
                      admin: 1606-1746.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4157</td>
                    <td>IPC, Burse Matthew Theige, will 1652; admin 1661. </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4158</td>
                    <td>
                      IPC, Burse James August Normel, 1653; diff., with collat.
                      1724; collat. 1726.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4159</td>
                    <td>
                      IPC, Burse Thomas Stapleton, JUD et Prof., pres. Coll.
                      Milius, by will of 14 June 1688: lawsuit against the
                      provisors by John Power.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4160</td>
                    <td>
                      IPC, Burse John O’Sullivan, STD, pres. IPC, by will of
                      1695.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4161</td>
                    <td>
                      IPC, Burse Florent O’Sullivan, STD, pres. IPC, dean St.
                      James church, Leuven by will of 21 June 1726: will,
                      admin., collat.: 1726-87
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4162</td>
                    <td>
                      IPC, Burse Raymond Magrath, from Ulster, Medecin in
                      Imperial Army, by will of 1 Mar. 1734, incorporated in
                      IPC, 21 June 1738: will, admin.: 1734-59.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4163</td>
                    <td>
                      IPC, Burse Thomas Stapleton, augmentation of Fund. By
                      Louis de Batty, from Beauraing, Canon of St. James, Leuven
                      by will of 2 June 1775: will and codicile of 24 Jan. 1776.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4164</td>
                    <td>
                      IPC, Burse John Kent, STL and pres. IPC, Canon St. Peter,
                      Leuven by will of 1778 (died 11 Nov. 1778): accounts exec.
                      will by Francis O’Hearn: 1771-80.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4166</td>
                    <td>
                      IDC, difficulties caused to superior of the College,
                      Father Cornick, and to religious, by Francis Martin in
                      Aug. 1717.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4385</td>
                    <td>
                      Coll. de Vaulx, accounts 1 Oct. 1605-29 Mar. 1657;
                      accounts burses de Vaulx June 1657-1 Jan. 1670.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4386</td>
                    <td>
                      Coll. de Vaulx, accounts Computus Culinarius Collegii del
                      Vaulx, 1654-57{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4387</td>
                    <td>
                      Coll. de Vaulx, Fund. Burses accounts and collat.
                      1587-1652.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4406</td>
                    <td>
                      SSTr., doc. Rel. to regents: o.a. complaints by Francis
                      O’Hearn regarding a too strict regent: 1786.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4408</td>
                    <td>
                      SSTr., doc. rel. to professors: Francis O’Hearn: 1776{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4409</td>
                    <td>SSTr., doc. rel. to students: 1679-1788.</td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4410</td>
                    <td>
                      SSTr., difficulties and disorders caused by the students,
                      excited by Fr. O’Hearn et certain of his collegues against
                      the Regent and Subregents: 26-27 Nov. 1777; 1778.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4411</td>
                    <td>
                      SSTr., Manuale Minervalium: accounts: lists of students:
                      Oct. 1747- Oct. 1787
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4412</td>
                    <td>
                      SSTr., Manuale Minervalium: accounts: lists of students:
                      1787-90{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4413</td>
                    <td>
                      SSTr., Lists of Price books distributed to laureats:
                      1723-91.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4414</td>
                    <td>
                      SSTr., Manuale Convictorum: receipts of boarders:
                      convictores et inquilini: 1772-80.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4415</td>
                    <td>
                      SSTr., doc. Rel. to students: lists, bills, accounts of
                      2nd and 3rd table: 1789-91.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4416</td>
                    <td>
                      SSTr., doc. Rel. accounts of students: law suits, letters
                      regarding debts: 1674-1790.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4417</td>
                    <td>
                      SSTr., doc. Law suits by the regent J.G. Moulan for unpaid
                      bills: 1780-84.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4582</td>
                    <td>
                      Flying Burses: Burse of seven bursaries by Thomas
                      Stapleton, 14 July 1688: admin collat. geneal.: 1688-1789{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4583</td>
                    <td>
                      Flying Burses: Burse Thomas Stapleton: dossier of a law
                      suit for rent, and unpaid sums: 1700-31.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4584</td>
                    <td>
                      Flying Burses: Burse Thomas Stapleton: dossier of a law
                      suit for contested parenty, by John Power, from Waterford,
                      against the provisors of this fundation: 1738-52{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4599</td>
                    <td>
                      Flying Burses: Burse Michel Hennessy, by will dated
                      July1730: rents and propreties. State of Fundation in 1795
                      and 1818; restablishment 1821.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4751</td>
                    <td>
                      Fac. Arts, Liber nominationum, 25 Apr. 1515- 26 Jan. 1547.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4752</td>
                    <td>
                      Fac. Arts, Liber nominationum, 1 Oct. 1558-15 Dec. 1624.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4753</td>
                    <td>
                      Fac. Arts, Liber nominationum, 5 Jan. 1624-23 Mar. 1680.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4754</td>
                    <td>
                      Fac. Arts, Liber nominationum, 27 Aug. 1680-17 Dec. 1754.
                    </td>
                  </tr>
                  <tr>
                    <td>RAL, OUL 4755</td>
                    <td>
                      Fac. Arts, Priviligies of nomination, 20 Jan. 1755-25 Apr.
                      1794.
                    </td>
                  </tr>
                  <tr>
                    <td>RD</td>
                    <td>
                      Edmond H.J. Reusens, Documents relatifs à l’histoire de
                      l’Université de Louvain (1425-1797), (5 vols, Louvain,
                      1893-1902). Table des notices... par Jean Buchet.
                      Partially reprinted, Brussels, 1999, with different
                      pagination.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RecMatr. </td>
                    <td>
                      Matriculation from the Receptors Registers, RAL, OUL,
                      273-292.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Redmond, Dr. French </td>
                    <td>
                      Gabriel OC. Redmond, ‘Dr. French, Bishop of Ferns’ in
                      J.W.S.E.I.A.S., iv (1898), pp 238-244.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Renehan </td>
                    <td>
                      L.F. Renehan, Collections in Irish church history, ed. D.
                      MacCarthy, (2 vols, Dublin, 1861-74).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Report 1731 </td>
                    <td>
                      ‘Report on the state of Popery, Ireland, 1731’ in Arch.
                      Hib., i (1912), pp 10-27; ii (1913), pp 108-156; iii
                      (1914), pp 124-159; iv (1915), pp 131-177.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RFBE </td>
                    <td>
                      Recueil des Fondations de Bourses d’Etudes, existant en
                      Belgique, (Brussels, 1873).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>RLA</td>
                    <td>Responsiones formales (pro Licentiatu Artium) </td>
                  </tr>
                  <tr>
                    <td>RMA</td>
                    <td>Responsiones formales (pro Magisterio Artium) </td>
                  </tr>
                  <tr>
                    <td>RN</td>
                    <td>Repertorium Novum </td>
                  </tr>
                  <tr>
                    <td>RPrA. </td>
                    <td>
                      Reusens, E.H.J., Promotions de la Faculté des Arts de
                      lUniversité de Louvain (1428-1797), 1er fascicule
                      (Promotions de 1428 à 1568) (Louvain, Ch. Peeters, 1869).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>S</td>
                    <td>Subdeacon</td>
                  </tr>
                  <tr>
                    <td>S</td>
                    <td>Subdeacon</td>
                  </tr>
                  <tr>
                    <td>S.P. Ó Mordha, Clogher Record Album</td>
                    <td>
                      S.P. Ó Mordha, ‘Heber MacMahon, soldier bishop of the
                      Confederation of Kilkenny’ in Joseph Duffy (ed.) Clogher
                      Record Album, (Monaghan, 1975), pp 41-62.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>SAL</td>
                    <td>Stadsarchief Leuven (City Archives Leuven)</td>
                  </tr>
                  <tr>
                    <td>SCPF</td>
                    <td>Sacra Congregazione di Propaganda Fide. </td>
                  </tr>
                  <tr>
                    <td>SCPF</td>
                    <td>
                      Sean Duffy, ‘The Loughross Gaelic Scholar: Doctor James
                      Woods’ in Creggan: Journal of The Creggan Local History
                      Society (1989), pp ??{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Seanchas Ardmhacha </td>
                    <td>Seanchas Ardmhacha </td>
                  </tr>
                  <tr>
                    <td>Sec. pr.</td>
                    <td>Secular Priest</td>
                  </tr>
                  <tr>
                    <td>Shaaber, 1975 </td>
                    <td>
                      M.A. Saaber, Check-list of Works of British Authors
                      Printed Abroad, in Languages other than English to 1641,
                      (New York, 1975).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>S.J. </td>
                    <td>Societas Jesu; Jesuit.</td>
                  </tr>
                  <tr>
                    <td>Spic. Oss. </td>
                    <td>
                      P.F. Moran (ed.), Spicilegium Ossoriense. A collection of
                      original letters and papers illustrative of the history of
                      the Irish Church from the reformation to 1800, (3 vols,
                      Dublin, 1874-84).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>SSTr. </td>
                    <td>
                      Collegium Sanctissimae Trinitatis; Holy Trinity College,
                      Leuven{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>STB </td>
                    <td>Sacrae Theologiae Baccalaureus</td>
                  </tr>
                  <tr>
                    <td>STBC </td>
                    <td>Sacrae Theologiae Baccalaureus Currens</td>
                  </tr>
                  <tr>
                    <td>STBF</td>
                    <td>Sacrae Theologiae Baccalaureus Formatus</td>
                  </tr>
                  <tr>
                    <td>STD</td>
                    <td>Sacrae Theologiae Doctor</td>
                  </tr>
                  <tr>
                    <td>STL</td>
                    <td>Sacrae Theologiae Licentiatus </td>
                  </tr>
                  <tr>
                    <td>STs</td>
                    <td>Sacrae Theologiae studiosus</td>
                  </tr>
                  <tr>
                    <td>Stroobant, Grand Conseil de Malines </td>
                    <td>
                      Louis Stroobant, Les Magistrats du Grand Conseil de
                      Malines, (Antwerp, 1903).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>T</td>
                    <td>Tonsure</td>
                  </tr>
                  <tr>
                    <td>UL</td>
                    <td>University of Leuven </td>
                  </tr>
                  <tr>
                    <td>Van de Wiel, Jansenistica </td>
                    <td>
                      Constant Van de Wiel, Jansenistica te Mechelen. Het
                      archief van het Aartsbisdom, (Leuven, 1988).
                    </td>
                  </tr>
                  <tr>
                    <td>V.G.</td>
                    <td>Vicar general </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </TabPane>
        <TabPane tabId="2">
          <div className="row">
            <div className="col-12">
              <h2>Spain database: database of Irish military in Spain</h2>
              <h3>Key to Database:</h3>
              This database was compiled by Dr Oscar Recio Morales
              (Complutensian University, Madrid, and Centre for Irish-Scottish
              Studies, Trinity College Dublin). The data was collected under the
              supervision of Prof. Ciaran Brady, Dr. Declan Downey (University
              College Dublin) and Prof. David Dickson (CISCS), and with the
              support in Spain of Dr. Enrique García Hernán ( CSIC-Madrid). It
              was funded by grants from the Irish Higher Education Authority’s
              PRTLI Cycle I and Cycle III initiatives. For this collection see
              Enrique García Hernán and Óscar Recio Morales (eds),{' '}
              <i>
                Extranjeros en el Ejército: militares irlandeses en la Sociedad
                Española, 1500–1818
              </i>{' '}
              (Madrid: Ministerio de Defensa, 2007), 15–17.
              <br />
              <br />
              <Table className="db-key" striped>
                <thead>
                  <tr>
                    <th>Abbreviation</th>
                    <th>Meaning</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>AGI</td>
                    <td>
                      Archivo General de Indias, Sevilla Con., Consulados
                      <br />
                      IG, Indiferente General
                      <br />
                      SD, Gobernacion Santo Domingo <br />
                    </td>
                  </tr>
                  <tr>
                    <td>AGR </td>
                    <td>
                      Archives Générales du Royaume/Algemeen Rijksarchief,
                      Burselas <br />
                      EA, Papiers d’Érat et de l’Audience
                      <br />
                      SEG, Secrétairerie d’État et de Guerre <br />
                    </td>
                  </tr>
                  <tr>
                    <td>AGS</td>
                    <td>
                      Archivo Général de Simancas, Valladolid <br />
                      CC, Camera de Castilla <br />
                      CG, Contaduría General <br />
                      CMC, Contaduría Mayor de Cuentas
                      <br />
                      CJH, Consejo y Juntas de Hacienda
                      <br />
                      DGT, Dirección Geneal del Tesoro <br />
                      E, Secretaría de Estado GA, Guerra Antigua
                      <br />
                      GJ, Gracia y Justicia
                      <br />
                      MPD, Mapas, planos y dibujos
                      <br />
                      SP, Secretarías Provinciales
                    </td>
                  </tr>
                  <tr>
                    <td>AGMAE</td>
                    <td>
                      Archivo General del Ministerio de Asuntos Exteriors,
                      Madrid
                      <br />
                      FSS, Fondo Santa Sede{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>AGMAB </td>
                    <td>Archivo General de la Marina Álvari de Bazán</td>
                  </tr>
                  <tr>
                    <td>AGMM</td>
                    <td>Archivo General Militar de Madrid</td>
                  </tr>
                  <tr>
                    <td>AGMS</td>
                    <td>Archivo General Militar de Segovia</td>
                  </tr>
                  <tr>
                    <td>AGVM</td>
                    <td>Archivo General de la Villa de Madrid</td>
                  </tr>
                  <tr>
                    <td>AHN</td>
                    <td>
                      Archivo Histórico Nacional, Madrid
                      <br />
                      CL, Sección de Clero Secular y Regular
                      <br />
                      CS, Sección de Consejos Suprimidos
                      <br />
                      E, Sección de Estado
                      <br />
                      OM, Sección de Órdens Militares
                    </td>
                  </tr>
                  <tr>
                    <td>AHPM </td>
                    <td>Archivo Histórico De Protocolos, Madrid </td>
                  </tr>
                  <tr>
                    <td>AHPR</td>
                    <td>Archivo Histórico del Palacio Real, Madrid </td>
                  </tr>
                  <tr>
                    <td>ANCh</td>
                    <td>Archivo Nacional de Chile, Santiago </td>
                  </tr>
                  <tr>
                    <td>ARCH</td>
                    <td>Archivo de la Real Chancillería, Valladoid </td>
                  </tr>
                  <tr>
                    <td>Archiv. Hib. </td>
                    <td>
                      Archivium Hibernicium: or Irish historical records.
                      Maynooth: Catholic Record Society of Ireland, 19912-{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>ASV</td>
                    <td>Archivo Segreto Vaticano, Ciudad del Vaticano </td>
                  </tr>
                  <tr>
                    <td>ASVen.</td>
                    <td>
                      Archivo di Stato di Venezia, Venecia Ambasciatori,
                      Dispacci degli Ambasciatori al Senato
                    </td>
                  </tr>
                  <tr>
                    <td>BAV</td>
                    <td>
                      Biblioteca Apostólica Vaticana, Ciudad del Vaticano Barb
                      lat., Colección Barberini{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>BL</td>
                    <td>
                      The British Library, Londres
                      <br />
                      Add. Msss., Additional Manuscripts
                      <br />
                      Eg. Mss Egerton Manuscripts
                      <br />
                      RB, Rare Books
                      <br />{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>BN</td>
                    <td>
                      Biblionteca National, Madrid
                      <br />
                      Mss., Manuscrito
                      <br />
                      R/, Raros <br />
                    </td>
                  </tr>
                  <tr>
                    <td>BNCh</td>
                    <td>Biblioteca del Museo Naval, Madrid </td>
                  </tr>
                  <tr>
                    <td>Cal. Carew Ms</td>
                    <td>
                      Calendar of the Carew Manuscripts preserved in the
                      Archiepiscopal Library at Lambeth. Brewer, J. S. y Bullen,
                      William (eds). Londres: Longmans, Green, Reader & Dyere,
                      1867-73 (6 Vols.){' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Cal. S.P. Ire </td>
                    <td>
                      Calendar of State papers, relating to Ireland, Londres:
                      <br />
                      The Public Record Office, 1860-1911 (24 Vols.){' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Cal. S.P. Ven </td>
                    <td>
                      Calendar of State Papers and Manuscripts, relating to
                      English affairs, existing in the
                      <br />
                      Archives and Collections of Venice, and in other libraries
                      of Northern Italy Londres:
                      <br />
                      The Public Record Office{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Cal. S.P. Spain </td>
                    <td>Calendar of State Papers, Spain </td>
                  </tr>
                  <tr>
                    <td>CODOIN</td>
                    <td>
                      Colección, de Documentos Inéditos para la Historia de
                      Espána, Madrid, 1863-90 (112 Vols.){' '}
                    </td>
                  </tr>
                  <tr>
                    <td>DIE </td>
                    <td>
                      Documentos néditos para la Historia de España. Madrid,1944
                      <br />
                      HMC Franciscan MSS Historical Manuscripts Commission
                      Report on Franciscan Manuscripts
                      <br />
                      preserved at the Convent, Merchants Quay, Dublin, eds. G.
                      D. Burtchaell & J. M. Rigg (Dublin 1906).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>IHCM </td>
                    <td>
                      Instituto de Historia y Cultura Militar, Ministerio de
                      Defensa. Madrid{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>CDHE</td>
                    <td>
                      Colección Depósito Histórico del Ejército CDG, Colección
                      General de Documentos Hist., Historiales{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>MHE</td>
                    <td>
                      Memorial Histórico Espánol. Colección de documentos,
                      opúsculos y antiguedades que publica la Read Arademia de
                      la Historia (1890-3)
                    </td>
                  </tr>
                  <tr>
                    <td>NLE </td>
                    <td>National Library of Ireland, Dublin </td>
                  </tr>
                  <tr>
                    <td>PRO</td>
                    <td>Public Record Office, Londres </td>
                  </tr>
                  <tr>
                    <td>RAH</td>
                    <td>Real Academia de la Historia, Madrid </td>
                  </tr>
                  <tr>
                    <td>Rel. Veneziani </td>
                    <td>
                      Le relazini degli Stati Europei lette al Senato dagli
                      Ambasciatori Veneziani nel Scolo Decimosettimo. Ed. De
                      Barozzi, Nioló y Berchat,
                      <br />
                      Guglielmo. Venecia: Serie I (Spagna), 1856 (Vol 1) y 1860
                      (Vol 2); Serie IV: Vol. único (Inghilterra), Venecia: 1863{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Sal Arch.</td>
                    <td>
                      Salamanca Archives. Russell Library, St Patrick’s College
                      (Maynooth) Irlanda{' '}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </TabPane>
        <TabPane tabId="3">
          <div className="row">
            <div className="col-12">
              <h2>France Database: database of Irish Military in France</h2>
              <h3>Key to France Database:</h3>
              The France database, compiled by Dr Colm Ó Conaill (Centre for
              Irish-Scottish Studies, Trinity College Dublin) houses 16,000
              biographical records. The information in this database was drawn
              from the regimental inspection records (
              <i>contrôles de troupes</i>) housed in the{' '}
              <i>Archives de l’Armée de Terre</i> in Vincennes. A French royal
              decree of 2 July 1716 introduced a system of troop records to the
              French army. French regimental chiefs were henceforth required to
              keep precise registers of all their sergeants, corporals and
              ordinary soldiers. These records provide the information in this
              database. From 1763 officer records were compiled by regiment,
              whereas previously this information had been gathered according to
              rank. These latter records have been collected but are not yet
              available in this database. <br />
              The troop inspections, carried out periodically contain the names
              of soldiers, rank, date of enrolment, date of leaving the
              company/regiment, age on enrolment, height, hair and eye colour
              and distinguishing physical attributes. An observations column
              gives the reason for leaving the company/regiment and
              miscellaneous information. Other information provided includes the
              company, regiment and year of inspection as well as the place of
              origin of each soldier. To date, over 16,000 separate entries have
              been recorded in the database. <br />
              <p>Key to Primary sources</p>
              Muster Rolls of the Irish Regiments in French Service 1715-1789
              <br />
              The following database is compiled from the controls (muster
              rolls) of the Irish regiments in French service available in the
              archives of the Service Historique de LArmée de Terre at
              Vincennes, France. Each muster roll in the archive has a file
              number which is set out in the following table.
              <br />
              <br />
              <Table className="db-key" striped>
                <thead>
                  <tr>
                    <th>Regiment </th>
                    <th>File No </th>
                    <th>Year/Location </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Clare </td>
                    <td>
                      1Yc256
                      <br />
                      1Yc257
                      <br />
                      1Yc259
                      <br />
                      1Yc259(2)
                      <br />
                      1Yc259(3)
                      <br />
                      1Yc258
                      <br />
                      1Yc258(2)
                      <br />
                      1Yc258(3)
                      <br />
                      1Yc258(4)
                      <br />
                      1Yc260
                      <br />
                      1Yc260(2)
                      <br />
                      1Yc260(3)
                      <br />
                      1Yc260(4)
                      <br />
                      1Yc142
                      <br />
                      1Yc142(3)
                      <br />
                      1Yc140
                      <br />
                      1Yc140(2)
                      <br />
                    </td>
                    <td>
                      1718
                      <br />
                      1729
                      <br />
                      1739
                      <br />
                      1744
                      <br />
                      1745
                      <br />
                      1751
                      <br />
                      1751
                      <br />
                      1757 Versailles <br />
                      1758 Cherbourg <br />
                      1763
                      <br />
                      1767
                      <br />
                      1767
                      <br />
                      1772
                      <br />
                      1775
                      <br />
                      1775
                      <br />
                      1776
                      <br />
                      1776
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td>Dillon</td>
                    <td>
                      1Yc303
                      <br />
                      1Yc304
                      <br />
                      1Yc306(1)
                      <br />
                      1Yc306(2)
                      <br />
                      1Yc306(3)
                      <br />
                      1Yc307
                      <br />
                      1Yc308
                      <br />
                      1Yc309
                      <br />
                    </td>
                    <td>
                      1716
                      <br />
                      1729
                      <br />
                      1737
                      <br />
                      1745
                      <br />
                      1762
                      <br />
                      17
                      <br />
                      17
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td>Lally</td>
                    <td>1Yc437</td>
                    <td>1748-56</td>
                  </tr>
                  <tr>
                    <td>Rooth/Walsh</td>
                    <td>
                      1Yc785
                      <br />
                      1Yc784
                      <br />
                      1Yc786(1)
                      <br />
                      1Yc786(2)
                      <br />
                      1Yc787
                      <br />
                      1Yc1064
                      <br />
                      1Yc1065
                      <br />
                    </td>
                    <td>
                      1729
                      <br />
                      1737
                      <br />
                      1749
                      <br />
                      1756
                      <br />
                      1763
                      <br />
                      1786
                      <br />
                      1786
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td>Bulkeley</td>
                    <td>
                      1Yc407
                      <br />
                      1Yc406
                      <br />
                      1Yc223
                      <br />
                      1Yc223(2)
                      <br />
                      1Yc223(3)
                      <br />
                      1Yc224
                      <br />
                      1Yc224(2)
                      <br />
                      1Yc224(3)
                      <br />
                      1Yc305
                      <br />
                      1Yc305(2)
                      <br />
                    </td>
                    <td>
                      1722
                      <br />
                      1729
                      <br />
                      1737
                      <br />
                      1745
                      <br />
                      1749
                      <br />
                      1757
                      <br />
                      1761 Wesel, May 28
                      <br />
                      1763
                      <br />
                      1776
                      <br />
                      1776
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td>Fitzjames</td>
                    <td>
                      3Yc198
                      <br />
                      3Yc199
                      <br />
                      3Yc112(1)
                      <br />
                      3Yc112(2)
                      <br />
                      3Yc112(3)
                      <br />
                      3Yc112(4)
                      <br />
                    </td>
                    <td>
                      1723
                      <br />
                      1729
                      <br />
                      1737
                      <br />
                      1748
                      <br />
                      1750
                      <br />
                      1758 Arras July 29
                      <br />{' '}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </TabPane>
        <TabPane tabId="4">
          <div className="row">
            <div className="col-12">
              <h2>
                Brocklissferte Database: database of Irish students in the
                Universities of Paris and Toulouse
              </h2>
              <h3>Key to Database:</h3>
              This database was compiled by L.W.B. Brockliss (Oxford) and
              Patrick Ferté (Toulouse). It contains biographical details of
              Irish clerical students who studied in Paris and Toulouse in the
              early modern period. The data presented in this biographical
              register was gathered from university records in Paris, Toulouse
              and elsewhere. Direct transliteration was made of the majority of
              college and seminary offices referred to in the original records.
              The terms loosely reflected the following English equivalents:{' '}
              <i>procurator</i> = secretary; <i>receiver</i> = treasurer;{' '}
              <i>oeconomus</i> = steward, domestic bursar;
              <i> gymnasiarchus, moderator, superior, praefectus</i> =
              principal; <i>provisor</i> = one of the four officials (one from
              each province) in charge of allocating scholarships in the Paris
              collège des Lombards.
              <br />
              <br />
              <strong>Key to primary sources</strong>
              <br />
              <Table className="db-key" striped>
                <thead>
                  <tr>
                    <th>Abbreviation</th>
                    <th>Meaning</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>A.D.</td>
                    <td>Archives départementales</td>
                  </tr>
                  <tr>
                    <td>A.D. H-G</td>
                    <td>Archives départementales de Haute-Garonne </td>
                  </tr>
                  <tr>
                    <td>A.M.T.</td>
                    <td>Archives Municipales de Toulouse </td>
                  </tr>
                  <tr>
                    <td>A.N.</td>
                    <td>Archives Nationales (Paris) </td>
                  </tr>
                  <tr>
                    <td>A.U.P.</td>
                    <td>
                      Archives de l’Université de Paris (Bibliothèque de la
                      Sorbonne){' '}
                    </td>
                  </tr>
                  <tr>
                    <td>B.M.</td>
                    <td>Bibliothèque municipale </td>
                  </tr>
                  <tr>
                    <td>B.U.T.1</td>
                    <td>
                      Bibliothèque Interuniversitaire de Toulouse (section
                      centrale et droit){' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Fr.</td>
                    <td>Français</td>
                  </tr>
                  <tr>
                    <td>L.</td>
                    <td>Latin</td>
                  </tr>
                </tbody>
              </Table>
              <i>Declaration</i> A printed list of the names of Irish students
              at the University of Paris who put their names to a 1651 pamphlet
              entitled ‘Declaration des Hibernois’ which defended Irish priests
              in the capital against accusations of Jansenism. The list is to be
              found at the end of the pamphlet; see Bibliothèque de la Sorbonne,
              Collection U 146, in 4°, n°1. <i>Journal</i> Journal de recette et
              dépense pour Mesrs. Les boursiers irlandais au collège des
              Grassins, Paris, 1750-1789; see A.N., H3 3648, dossier 4, no pag.
              A full list of MS sources used in compiling the list can be found
              under section I, nos30-35, 89-93.
              <br />
              <br />
              <strong>Key to secondary sources</strong>
              <br />
              <br />
              <Table className="db-key" striped>
                <thead>
                  <tr>
                    <th>Abbreviation</th>
                    <th>Meaning</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Arch. Hib. </td>
                    <td>Archivium Hibernicum.</td>
                  </tr>
                  <tr>
                    <td>Coll. Hib. </td>
                    <td>Collectanea Hibernica.</td>
                  </tr>
                  <tr>
                    <td>IER </td>
                    <td>Irish Eccesiastical Record.</td>
                  </tr>
                  <tr>
                    <td>Bellesheim</td>
                    <td>
                      A. Bellesheim, Geschichte der katholischen Kirche in
                      Irland (3 vols., Mainz, 1890-1891).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Boylan</td>
                    <td>
                      H. Boylan, Dictionary of Irish biography (London, 1978).
                    </td>
                  </tr>
                  <tr>
                    <td>Boyle, ‘Glimpses’ </td>
                    <td>
                      P. Boyle, ‘Glimpses of Irish collegiate life in the
                      seventeenth and eighteenth centuries,
                      <br />
                      I.E.R., XI (1902), 432-450.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Boyle I.C.P.</td>
                    <td>
                      P. Boyle, ‘The Irish College at Paris, 1578-1901’ (London,
                      1901).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Boyle, St.Nic. </td>
                    <td>
                      P. Boyle, ‘Some Irish ecclesiastics at the seminary of
                      Saint-Nicolas du Chardonnet, Paris (A.D. 1735-1791)’, IER,
                      XXVIII (1910), 480-491.
                    </td>
                  </tr>
                  <tr>
                    <td>Brady</td>
                    <td>
                      J. Brady, ‘Irish colleges in the Low Countries’, Arch.
                      Hib. 14 (1949), 56-91.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Brady, Press</td>
                    <td>
                      J. Brady, Catholics and Catholicism in the eighteenth
                      century Press (Maynooth, 1965).
                    </td>
                  </tr>
                  <tr>
                    <td>DeBurgo</td>
                    <td>
                      T. de Burgo, Hibernica Dominicana (Cologne, [Kilkenny],
                      1772 ; supplement, 1776.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Burke</td>
                    <td>
                      W. P. Burke, The Irish priests in penal times (Waterford,
                      1914).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Burton, St. Cregs H. Burton (ed.),</td>
                    <td>
                      ‘The register book of St. Gregory’s college at Paris
                      1667-1786’, in Catholic Record Society, XI (1917, 93-161
                      (Miscellanea, p.II).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Corcoran</td>
                    <td>
                      T. Corcoran, Some lists of Catholic lay teachers and their
                      illegal schools in the penal times (Dublin, 1932).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Cregan</td>
                    <td>
                      D. F. Cregan, ‘The social and cultural background of a
                      Counter-Reformation episcopate 1618-1660’ in A. Cosgrove
                      and D. McCartney (eds. Studies in Irish History presented
                      to R. Dudley Edwards (Dublin, 1979), pp. 85-117.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Crone</td>
                    <td>
                      J. S. Crone, Concise Dictionary of Irish biography
                      (London, 1920).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Daumet</td>
                    <td>
                      G. Daumet, ‘Notices sur les établissements religieux
                      anglais, écossais et
                      <br /> irlandais fondés à Paris avant la révolution’,
                      Mémoires de la société de l’histoire de Paris et
                      l’Ile-de-France, XXXIX (1912), 1-223.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>DBF</td>
                    <td>Dictionnaire de Biographie française. </td>
                  </tr>
                  <tr>
                    <td>DNB</td>
                    <td>Dictionary of National Biography. </td>
                  </tr>
                  <tr>
                    <td>Gerin</td>
                    <td>
                      Ch. Gerin, Recherches historiques
                      <br />
                      sur lAssemblée du clergé de France de 1682 (2nd ed.,
                      Paris, 1870).
                    </td>
                  </tr>
                  <tr>
                    <td>Giblin</td>
                    <td>
                      C. Giblin, ‘Irish exiles in Catholic Europe’ in P. J.
                      Corish, A History of Irish Catholicism, vol. IV, ch. 3
                      (Dublin, 1971).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Hayes</td>
                    <td>
                      R. Hayes, A Biographical Dictionary of Irishmen in France
                      (Dublin, 1949).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Hayes, MS.</td>
                    <td>
                      R. Hayes, ‘An unpublished eighteenth-century Franco-Irish
                      manuscript’, Studies, 28 (1939), 475-84.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Jennings</td>
                    <td>
                      B. Jennings, ‘Irish students at the university of
                      Louvain’, in Sylvester O’Brien (ed.), Measgra i gcuimhne
                      Mhichíl Ui Chléirigh (Dublin, 1944), 74-97{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Pasquier </td>
                    <td>
                      {' '}
                      E. Pasquier, ‘Students from the British Isles at the
                      ancient faculty of medicine at Angers’, Notes and Queries,
                      April 1933, 212-220.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Renehan</td>
                    <td>
                      {' '}
                      L. F. Renehan, Collections in Irish Church history, ed. D.
                      MacCarthy (2 vols., Dublin, 1861-1874).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Swords</td>
                    <td>
                      {' '}
                      L. Swords (ed.), The Irish/French conection (Paris, 1978).{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Swords, Doc.</td>
                    <td>
                      L. Swords, ‘Documents: history of the Irish College,
                      Paris, 1578-1800: a calendar of the papers of the Irish
                      college Paris’, Arch. Hib., XXXV (1980), 3-233.{' '}
                    </td>
                  </tr>
                  <tr>
                    <td>Walsh, ICB</td>
                    <td>
                      T.J. Walsh, ‘Some records of the Irish college at
                      Bordeaux’, Arch. Hib. (1950), 92-141.
                    </td>
                  </tr>
                  <tr>
                    <td>Walsh, ICT</td>
                    <td>
                      T. J. Walsh, ‘The Irish college at Toulouse’, Journal of
                      the Cork Historical and Archaeological Society, LIX
                      (1954), 22-33.{' '}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <br />
              <br />
              <strong>Key to abbreviations</strong>
              <br />
              <br />
              <Table className="db-key" striped>
                <thead>
                  <tr>
                    <th>Abbreviation</th>
                    <th>Meaning</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>B</td>
                    <td>Bachelor</td>
                  </tr>
                  <tr>
                    <td>BUI</td>
                    <td>bachelor in utroque jure (vide infra: UI)</td>
                  </tr>
                  <tr>
                    <td>BcanLaw</td>
                    <td>bachelor of canon law </td>
                  </tr>
                  <tr>
                    <td>canLaw</td>
                    <td>canon law </td>
                  </tr>
                  <tr>
                    <td>D</td>
                    <td>doctor </td>
                  </tr>
                  <tr>
                    <td>DcanLaw</td>
                    <td>doctor of canon law</td>
                  </tr>
                  <tr>
                    <td>Deac.</td>
                    <td>deacon</td>
                  </tr>
                  <tr>
                    <td>Dép. </td>
                    <td>present French département </td>
                  </tr>
                  <tr>
                    <td>DUI</td>
                    <td> doctor in utroque jure (vide infra: UI) </td>
                  </tr>
                  <tr>
                    <td>Fac.</td>
                    <td>faculty</td>
                  </tr>
                  <tr>
                    <td>I.C.</td>
                    <td>Irish College</td>
                  </tr>
                  <tr>
                    <td>I.C.P.</td>
                    <td>Paris Irish College</td>
                  </tr>
                  <tr>
                    <td>I.C.T.</td>
                    <td>Toulouse Irish College</td>
                  </tr>
                  <tr>
                    <td>L.</td>
                    <td> licentiate </td>
                  </tr>
                  <tr>
                    <td>LcanLaw</td>
                    <td> licentiate in canon law </td>
                  </tr>
                  <tr>
                    <td>L.U.I.</td>
                    <td>licentiate in utroque jure </td>
                  </tr>
                  <tr>
                    <td>MA</td>
                    <td>Master of Arts</td>
                  </tr>
                  <tr>
                    <td>Matric.</td>
                    <td>
                      matriculated (name first appears in the Paris Liber
                      Rectoris){' '}
                    </td>
                  </tr>
                  <tr>
                    <td>M.O.</td>
                    <td>received the four minor orders </td>
                  </tr>
                  <tr>
                    <td>Pauper</td>
                    <td>
                      sub titulo paupertatis (law students who registered under
                      this condition probably paid no dues to the faculty)
                    </td>
                  </tr>
                  <tr>
                    <td>pr.</td>
                    <td>
                      priest (preceding a date: ordained as priest; at the end
                      of a phrase, present status){' '}
                    </td>
                  </tr>
                  <tr>
                    <td>reg.</td>
                    <td>
                      registered (name first appears in a faculty quarterly
                      register)
                    </td>
                  </tr>
                  <tr>
                    <td>subd.</td>
                    <td>subdeacon</td>
                  </tr>
                  <tr>
                    <td>Th.</td>
                    <td>theology </td>
                  </tr>
                  <tr>
                    <td>tons.</td>
                    <td>tonsured</td>
                  </tr>
                  <tr>
                    <td>U.I.</td>
                    <td>
                      in utroque jure (i.e. canon and civil law before 1679;
                      after 1679: canon, civil and French law); BUI: bachelor in
                      utroque jure; LUI: licentiate in utroque jure; DUI: doctor
                      in utroque jure.
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </TabPane>
        <TabPane tabId="5">
          <div className="row">
            <div className="col-12">
              <h2>
                InnsStudents Database: database of Irish students in the early
                modern Inns of Court.
              </h2>
              <h3>Key to Database</h3>
              <p>
                This dataset was compiled by Bríd McGrath as a basis for a study
                of Irishmen at the four London Inns of Court which was published
                as B. McGrath, &apos;Ireland and the third university:
                attendance at the Inns of Court, 1603-1649. in D. Edwards (ed.),
                <i>
                  Regions and rulers in Ireland, 1100-1650: essays for Kenneth
                  Nicholls.
                </i>{' '}
                Dublin: Four Courts, 2004. pp 217-236.
              </p>
              <p>
                The Inns of court -Gray’s Inn, Lincoln’s Inn and the Inner and
                Middle Temples - provided education in the common law for those
                intending to become lawyers but they were also used as a means
                of providing basic training in the law for gentlemen and as a
                cultural experience in the English capital; as such, they were
                referred to as England’s ‘Third University.’ Admission to the
                inns was opened to Irishmen under 33 Henry VIII c. 3 and those
                who wished to practice as common lawyers were required to spend
                at least five years (frequently seven) studying at the inns
                before being called to the bar. Only a minority of students
                remained for the required period, most spending only one or two
                years in London, gaining a grounding in law and engaging in
                wider cultural pursuits, including the theatre.
              </p>
              <p>
                Entrants to the inns were required to provide two manucaptors;
                for English students, these were generally senior members of the
                inns who acted as mentors and guaranteed their good behaviour
                and debts. Irish students, however, acted as manucaptors for
                each other; while this may have meant that they received a lower
                quality of education and less pastoral care and they remained
                outside the influential circles which could promote their legal
                careers, it does mean that it is possible to ascertain how long
                some students remained at the inns and which intended to make a
                career as lawyers.
              </p>
              <p>
                The dataset was complied from the admission records of the four
                inns of court; all except the largest, Gray’s Inn, included
                manucaptors’ names in their registers. All these registers were
                published but manucaptors’ names were included only for the
                Middle Temple. However, the manuscript records of the Inner
                Temple and Lincoln’s Inns did provide manucaptors’ names and
                these are included in this dataset.
              </p>
              <p>
                The dataset includes all Irishmen admitted to the inns, their
                admission dates, fathers’ names, where given, home addresses,
                religious affiliation, manucaptors’ names, the cost of their
                admission and, where given, their earlier admission to one of
                the chancery inns which trained attorneys; time spent at these
                inns counted towards the qualifying period for being called to
                the bar. Where known, information is also provided about their
                later careers, including their admission to the King’s Inns in
                Dublin, membership of parliament, adherence to the Confederation
                of Kilkenny and other additional information.
              </p>
              <p>
                As a result, the dataset provides unique information about the
                Irishmen at the inns, their friendship networks which endured
                through their lives and careers back in Ireland and some
                baseline data on the length of time they remained at the inns
                and their later careers.
              </p>
              <p>
                Further reading:
                <ul>
                  <li>
                    B. McGrath, &apos;Ireland and the third university:
                    attendance at the Inns of Court, 1603-1649. in D. Edwards
                    (ed.),{' '}
                    <i>
                      Regions and rulers in Ireland, 1100-1650: essays for
                      Kenneth Nicholls.
                    </i>{' '}
                    Dublin: Four Courts, 2004. pp 217-236.
                  </li>
                  <li>
                    D.F. Cregan, &apos;Irish Catholic admissions to the Inns of
                    Court, 1558-1625. &apos;<i>Irish Jurist,</i> New ser., 95,
                    (1970). pp. 95-114.
                  </li>
                  <li>
                    C. Kenny, ‘Badge of servitude’? The Irish at England&apos;s
                    inns of court.’ <i>Proc. R.I.A.,</i> 119C, (2019). pp
                    199-227.
                  </li>
                  <li>
                    J. Peacey, ‘Led by the hand: manucaptors and patronage at
                    Lincoln&apos;s Inn in the seventeenth century.’{' '}
                    <i>Journal of Legal History,</i> (April 1997)18(1), pp
                    26-44.
                  </li>
                  <li>
                    B. McGrath, ‘The Recruiter returns to the Irish parliament,
                    1642-1648.’ In P. Little (ed.),{' '}
                    <i>
                      Ireland in crisis: war, politics and religion 1641-50.
                    </i>
                    (Manchester, Manchester University Press, 2019). pp 119-137.
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Guide;
